import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';

@Component({
  selector: 'app-supply-base-management-modal',
  templateUrl: './supply-base-management-modal.component.html',
  styleUrls: ['./supply-base-management-modal.component.css']
})
export class SupplyBaseManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  costList = [];
  allocationList = [];
  costDtoList: any[] = [];
  costTypeCodeList: any[];
  costNameList: any[] = [];
  selectedCostNameList: any[] = [];
  tcrBudgetCostTypeList: any[] = [];
  tcBudgetTypeList: any[] = [];
  allocationYearABList: any[] = [];
  allocationYearCFList: any[] = [];
  selectedSupplyBaseCode: any;
  addCostFlag: any = true;
  addAllocationFlag: any = true;
  isReadonly: boolean = false;
  costCountingLink = 0;
  allocationCountingLink = 0;
  masterObj: any;
  paramId: any;
  pageMode: any;
  blockCostList: any[] = [
    {
      Code: "ALL",
      BlockName: "ALL",
    },
  ];
  blockAllocationList: any[] = [];
  updateBy: any;
  updateDate: any;
  chosenYearDate: Date;
  checkAllocation: boolean;
  costDefaluteList: any[];
  defaluteFlagList: any[] = [];
  yearList: any[] = [];
  supplyBaseCostList: any[] = [];
  budgetTypeCode: any;
  countInput = 1;

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
    if (this.masterObj &&this.masterObj != null) { 
        for (var block of this.masterObj.tcBlock) {
          this.blockCostList.push(block);
        }
        this.blockAllocationList = this.masterObj.tcBlock;
        this.costDtoList = this.masterObj.costListDto;
        this.costTypeCodeList = this.masterObj.tcCostType;
        this.tcrBudgetCostTypeList = this.masterObj.tcrBudgetCostType;
        this.tcBudgetTypeList = this.masterObj.tcBudgetType;
    }
  
    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 11; i++) {
      if (i == 5) {
        this.yearList.push({ Code: currentYear });
      }
      else if (i < 5) {
        this.yearList.push({ Code: currentYear - (5 - i) });
      }
      else if (i > 5) {
        this.yearList.push({ Code: currentYear + (i - 5) });
      }
    }
    let supplyBaseName: string;
    let activeFlag: boolean;


    if (this.masterObj &&
      this.masterObj != null &&
      this.masterObj.resolverData &&
      this.masterObj.resolverData != null
    ) {
      supplyBaseName = this.masterObj.resolverData.SupplyBaseName;
      activeFlag = this.masterObj.resolverData.ActiveFlag;


      this.formZone = this.formBuilder.group({
        'SupplyBaseName': new FormControl({ value: supplyBaseName, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'ActiveFlag': new FormControl({ value: activeFlag == true ? true : activeFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'SupplyBaseCostList': this.formBuilder.array([]),
        'SupplyBaseAllocationABList': this.formBuilder.array([]),
        'SupplyBaseAllocationCFList': this.formBuilder.array([]),
        'CreateDate': new FormControl({ value: "", disabled: true }),
        'CreateName': new FormControl({ value: "", disabled: true }),
        'UpdateDate': new FormControl({ value: "", disabled: true }),
        'UpdateName': new FormControl({ value: "", disabled: true }),
      });

      this.checkAllocation = this.masterObj.resolverData.AllocationFlag == true ? true : this.masterObj.resolverData.AllocationFlag == false ? false : false

      if (this.checkAllocation) {
        this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.BudgetTypeCode == "SS" && item.DefaluteFlag == true);
        var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SS"));
      } else {
        this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.BudgetTypeCode == "SN" && item.DefaluteFlag == true);
        var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SN"));
      }

      for (var budgetCostFilter of budgetCostFiltedList) {
        this.supplyBaseCostList.push(this.costTypeCodeList.find(x => x.Code == budgetCostFilter.CostTypeCode));
      }

    } else {
      this.formZone = this.formBuilder.group({
        'SupplyBaseName': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'ActiveFlag': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'SupplyBaseCostList': this.formBuilder.array([]),
        'SupplyBaseAllocationABList': this.formBuilder.array([]),
        'SupplyBaseAllocationCFList': this.formBuilder.array([]),
        'CreateDate': new FormControl({ value: "", disabled: true }),
        'CreateName': new FormControl({ value: "", disabled: true }),
        'UpdateDate': new FormControl({ value: "", disabled: true }),
        'UpdateName': new FormControl({ value: "", disabled: true }),
      });
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.loadCostDefaluteFlag(this.costDefaluteList);
      this.formZone.get("ActiveFlag").setValue(true);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.SupplyBaseCostList &&
      this.masterObj?.resolverData.SupplyBaseCostList?.length > 0) {
      this.loadCost(this.masterObj?.resolverData.SupplyBaseCostList);
    }

  /*  console.log("SupplyBaseAllocationList");
    console.log(this.masterObj?.resolverData.SupplyBaseAllocationList);*/

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.SupplyBaseAllocationList &&
      this.masterObj?.resolverData.SupplyBaseAllocationList?.length > 0) {
      if (this.masterObj?.resolverData.SupplyBaseAllocationList.filter(x => x.AbFlag == true)) {
        this.loadAllocationAB(this.masterObj?.resolverData.SupplyBaseAllocationList);
      }

      if (this.masterObj?.resolverData.SupplyBaseAllocationList.filter(x => x.AbFlag == false)) {
        this.loadAllocationCF(this.masterObj?.resolverData.SupplyBaseAllocationList);
      }
    }

  }

  loadCost(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        let checkAllowcationFlag = this.costDtoList.find(x => x.Id == costListObj.CostId && x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag).AllocateFlag;
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        if (this.checkAllocation) {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SS"));
          this.costItems.push(this.formBuilder.group({
            "BlockCode": new FormControl({ value: costListObj.BlockCode == null ? "ALL" : costListObj.BlockCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
          }));
        } else {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SN"));
          this.costItems.push(this.formBuilder.group({
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: (checkAllowcationFlag || this.isReadonly) }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
          }));
        }
      }
      this.checkAddCost();
    }
  }

  loadCostDefaluteFlag(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        if (this.checkAllocation) {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SS"));
          this.costItems.push(this.formBuilder.group({
            "BlockCode": new FormControl({ value: costListObj.BlockCode == null ? "ALL" : costListObj.BlockCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
          }));
        } else {
          this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag && x.BudgetTypeCode == "SN"));
          this.costItems.push(this.formBuilder.group({
            "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
            "Value": new FormControl({ value: costListObj.Value, disabled: (costListObj.AllocateFlag || this.isReadonly) }, Validators.compose([Validators.required])),
            "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
          }));
        }

      }
      this.checkAddCost();
    }
  }

  addCost() {
    if (this.checkAllocation) {
      this.costItems.push(this.formBuilder.group({
        "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
      }));
    } else {
      this.costItems.push(this.formBuilder.group({
        "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
      }));
    }
    this.checkAddCost();
  }


  deleteCost(index) {
    this.costItems.removeAt(index);
    this.costNameList.splice(index, 1);
    this.defaluteFlagList.splice(index, 1);
    this.addCostFlag = true;
  }

  checkAddCost() {

    if (!this.checkAllocation &&
      (this.costItems.length >= this.costDtoList.filter(x => x.VariableTypeCode == 'C' && x.BudgetTypeCode == "SN").length)) {

      this.addCostFlag = false;
    }

  }

  costTypeCodeChangeValue(idx) {
    if (this.costItems && this.costItems.length > 0) {
      var item = this.costItems.at(idx);
      item.get("CostId").setValue(null);
      //console.log(this.costDtoList)
      if (this.checkAllocation) {
        this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false && x.BudgetTypeCode == "SS");
      } else {
        this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false && x.BudgetTypeCode == "SN");
      }

    }
  }

  //onSearchChange(searchValue: string): void {
  //  this.supplyBaseCostList = [];
  //  if (!this.formZone.get('SupplyBaseName').invalid) {
  //    this.checkAllocation = this.formZone.get("SupplyBaseName").value.toUpperCase() == 'TAKITTA' ? true : false;
  //  } else {
  //    this.checkAllocation = false;
  //  }
  //  if (this.checkAllocation) {
  //    this.countInput = 0;
  //    this.clearFormArray(this.costItems);
  //    var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SS"));
  //  } else {
  //    if (this.countInput == 0) {
  //      this.clearFormArray(this.costItems);
  //    }
  //    var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => (x.BudgetTypeCode == "SN"));
  //    this.countInput++;
  //  }
  //  for (var budgetCostFilter of budgetCostFiltedList) {
  //    this.supplyBaseCostList.push(this.costTypeCodeList.find(x => x.Code == budgetCostFilter.CostTypeCode));
  //  }
  //}

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  checkAddAllocationAB() {
    if (this.allocationList.length >= this.masterObj.tcBlock.length) {
      this.addAllocationFlag = false;
    }
  }

  checkAddAllocationCF() {
    if (this.allocationList.length >= this.masterObj.tcBlock.length) {
      this.addAllocationFlag = false;
    }
  }

  loadAllocationAB(allocationList) {
    let allocationABList = allocationList.filter(x => x.AbFlag == true);
    if (allocationABList && allocationABList.length > 0) {
      for (var i = 0; i < allocationABList.length; i++) {
        var allocationObj = allocationABList[i];
        this.allocationYearABList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
        this.allocationABItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: allocationObj.Id, disabled: this.isReadonly }),
          "BlockCode": new FormControl({ value: allocationObj.BlockCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "Percentage": new FormControl({ value: allocationObj.Percentage, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: allocationObj.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "StartYear": new FormControl({ value: parseInt(allocationObj.StartYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "EndYear": new FormControl({ value: parseInt(allocationObj.EndYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "UpdateDate": new FormControl({ value: CommonUtilService.formatDateTime(allocationObj.UpdateDttm), disabled: true }),
          "UpdateBy": new FormControl({ value: allocationObj.UpdateName, disabled: true }),
          "AbFlag": new FormControl({ value: allocationObj.AbFlag, disabled: true }),
          "ParentId": new FormControl({ value: allocationObj.ParentId, disabled: this.isReadonly }),
        }));

        this.startYearABChangeValue(i, false);
        this.endYearABChangeValue(i, false);

        this.checkAddAllocationAB();
      }
    }
  }

  loadAllocationCF(allocationList) {
    let allocationCFList = allocationList.filter(x => x.AbFlag == false);
    if (allocationCFList && allocationCFList.length > 0) {
      for (var i = 0; i < allocationCFList.length; i++) {
        var allocationObj = allocationCFList[i];
        this.allocationYearCFList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
        this.allocationCFItems.push(this.formBuilder.group({
          "Id": new FormControl({ value: allocationObj.Id, disabled: this.isReadonly }),
          "BlockCode": new FormControl({ value: allocationObj.BlockCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "Percentage": new FormControl({ value: allocationObj.Percentage, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: allocationObj.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "StartYear": new FormControl({ value: parseInt(allocationObj.StartYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "EndYear": new FormControl({ value: parseInt(allocationObj.EndYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "UpdateDate": new FormControl({ value: CommonUtilService.formatDateTime(allocationObj.UpdateDttm), disabled: true }),
          "UpdateBy": new FormControl({ value: allocationObj.UpdateName, disabled: true }),
          "AbFlag": new FormControl({ value: allocationObj.AbFlag, disabled: true }),
          "ParentId": new FormControl({ value: allocationObj.ParentId, disabled: this.isReadonly }),
        }));

        this.startYearCFChangeValue(i);
        this.endYearCFChangeValue(i);

        this.checkAddAllocationCF();
      }
    }
  }

  addAllocationAB() {
    this.allocationYearABList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
    let tempId = Math.random();
    this.allocationABItems.push(this.formBuilder.group({
      "Id": new FormControl({ value: tempId, disabled: this.isReadonly }),
      "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Percentage": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
      "StartYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "EndYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "UpdateDate": new FormControl({ value: "", disabled: true }),
      "UpdateBy": new FormControl({ value: "", disabled: true }),
      "AbFlag": new FormControl({ value: true, disabled: true }),
    }));
    this.addAllocationCF(tempId);
    this.checkAddAllocationAB();
  }

  addAllocationCF(tempId) {
    this.allocationYearCFList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
    this.allocationCFItems.push(this.formBuilder.group({
        "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "Percentage": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
        "StartYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "EndYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
        "UpdateDate": new FormControl({ value: "", disabled: true }),
        "UpdateBy": new FormControl({ value: "", disabled: true }),
        "AbFlag": new FormControl({ value: false, disabled: true }),
        "ParentId": new FormControl({ value: tempId ? tempId :null, disabled: this.isReadonly })
      }));
    this.checkAddAllocationCF();
  }

  deleteAllocationAB(indexAB) {


    let allocationABItem = this.allocationABItems.at(indexAB).value;
    let indexCF = (this.allocationCFItems as FormArray).controls.findIndex(
      x => x.get("ParentId").value == allocationABItem.Id,
    );

    this.allocationYearABList.splice(indexAB, 1);
    this.allocationYearCFList.splice(indexCF, 1);
    this.allocationABItems.removeAt(indexAB);
    this.allocationCFItems.removeAt(indexCF);

    this.addAllocationFlag = true;
   
  }

  deleteAllocationCF(index) {
    this.allocationYearCFList.splice(index, 1);

    this.allocationCFItems.removeAt(index);
    this.addAllocationFlag = true;
  }

  get costItems(): FormArray {
    return this.formZone.get('SupplyBaseCostList') as FormArray;
  }

  get allocationABItems(): FormArray {
    return this.formZone.get('SupplyBaseAllocationABList') as FormArray;
  }

  get allocationCFItems(): FormArray {
    return this.formZone.get('SupplyBaseAllocationCFList') as FormArray;
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";
    if (this.checkAllocation) {
      requestObj.AllocationFlag = true;
      if (this.allocationABItems.length == 0) {
        message = "The data cannot be save. Because the allocation AB Stage is null.";
      }

      if (this.allocationCFItems.length == 0) {
        message = "The data cannot be save. Because the allocation CF Stage is null.";
      }
      const allocationABList = requestObj.SupplyBaseAllocationABList
      const allocationCFList = requestObj.SupplyBaseAllocationCFList

      requestObj.SupplyBaseAllocationList = [...allocationABList, ...allocationCFList];
    }

    if (!this.formZone.valid || message) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }
      return;
    }


    if (this.paramId && this.paramId != "0") {
      requestObj["IdEncoded"] = this.paramId;
    }

    if (this.costItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the cost is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    this.MasterData.checkSupplyBaseCost(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveSupplyBase(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/supply-base-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/supply-base-management']);
  }

  /* "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
 "Percentage": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
   "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
     "StartYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
       "EndYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
         "UpdateDate": new FormControl({ value: "", disabled: true }),
           "UpdateBy": new FormControl({ value: "", disabled: true }),
             "AbFlag": new FormControl({ value: false, disabled: true })*/

  allocationABItemChange(index) {
    var allocationABItem = this.allocationABItems.at(index).value;

    (this.allocationCFItems as FormArray).controls.find(
      x => x.get("ParentId").value == allocationABItem.Id
    ).patchValue({
      BlockCode: allocationABItem.BlockCode,
      Percentage: allocationABItem.Percentage,
      ActiveFlag: allocationABItem.ActiveFlag,
    });

    //this.formZone.updateValueAndValidity();
  }

  startYearABChangeValue(index, editFlag) {
    let allocationABItem = this.allocationABItems.at(index).value;
    let startYear = allocationABItem.StartYear;

    if (editFlag) {

      let indexCF = (this.allocationCFItems as FormArray).controls.findIndex(
        x => x.get("ParentId").value == allocationABItem.Id,
      );

      this.allocationYearABList[index]["endYearList"] = this.yearList.filter(x => x.Code >= startYear);
      this.allocationYearCFList[indexCF]["endYearList"] = this.yearList.filter(x => x.Code >= startYear);

      (this.allocationCFItems as FormArray).controls.find(
        x => x.get("ParentId").value == allocationABItem.Id
      ).patchValue({
        StartYear: startYear,
      });
      this.formZone.updateValueAndValidity();

    } else {

      this.allocationYearABList[index]["endYearList"] = this.yearList.filter(x => x.Code >= startYear);
      this.formZone.updateValueAndValidity();

    }
  }

  startYearCFChangeValue(index) {
    var allocationItem = this.allocationCFItems.at(index);
    var startYear = allocationItem.get("StartYear").value;
    this.allocationYearCFList[index]["endYearList"] = this.yearList.filter(x => x.Code >= startYear);
    this.formZone.updateValueAndValidity();
  }

  endYearABChangeValue(index, editFlag) {
    let allocationABItem = this.allocationABItems.at(index).value;
    let endYear = allocationABItem.EndYear;

    if (editFlag) {

      let indexCF = (this.allocationCFItems as FormArray).controls.findIndex(
        x => x.get("ParentId").value == allocationABItem.Id,
      );

      this.allocationYearABList[index]["startYearList"] = this.yearList.filter(x => x.Code <= endYear);
      this.allocationYearCFList[indexCF]["startYearList"] = this.yearList.filter(x => x.Code <= endYear);

      (this.allocationCFItems as FormArray).controls.find(
        x => x.get("ParentId").value == allocationABItem.Id
      ).patchValue({
        EndYear: endYear,
      });
      this.formZone.updateValueAndValidity();

    } else {

      this.allocationYearABList[index]["startYearList"] = this.yearList.filter(x => x.Code <= endYear);
      this.formZone.updateValueAndValidity();

    }
  }

  endYearCFChangeValue(index) {
    var allocationItem = this.allocationCFItems.at(index);
    var endYear = allocationItem.get("EndYear").value;
    this.allocationYearCFList[index]["startYearList"] = this.yearList.filter(x => x.Code <= endYear);
    this.formZone.updateValueAndValidity();
  }

}
