import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';

@Component({
  selector: 'app-department-management-modal',
  templateUrl: './department-management-modal.component.html',
  styleUrls: ['./department-management-modal.component.css']
})
export class DepartmentManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  dtOptions: any = {};
  isReadonly: boolean = false;
  paramId: any;
  pageMode: any;
  masterObj: any;
  paramCode: any;
  SupportTypeList: any;
  defaluteFlagList: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  projectList: any[] = [];
  activityList: any[] = [];
  costDtoList: any[] = [];
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );

    if (this.masterObj &&
      this.masterObj != null) { 
      this.SupportTypeList = this.masterObj.tcSupportType.filter(x => x.ActiveFlag == true);
    }


    if (this.masterObj &&
      this.masterObj != null &&
      this.masterObj.resolverData &&
      this.masterObj.resolverData != null
    ) {
     

      this.formZone = this.formBuilder.group({
        'Code': new FormControl({ value: this.masterObj.resolverData.Code, disabled: (this.masterObj.resolverData.Code) }, Validators.compose([Validators.required])),
        'Description': new FormControl({ value: this.masterObj.resolverData.Description, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'ActiveFlag': new FormControl({ value: this.masterObj.resolverData.ActiveFlag == true ? true : this.masterObj.resolverData.ActiveFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'SupportTypeCode': new FormControl({ value: this.masterObj.resolverData.SupportTypeCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'CreateDate': new FormControl({ value: "", disabled: true }),
        'CreateName': new FormControl({ value: "", disabled: true }),
        'UpdateDate': new FormControl({ value: "", disabled: true }),
        'UpdateName': new FormControl({ value: "", disabled: true }),
      });
    } else {
      this.formZone = this.formBuilder.group({
        'Code': new FormControl({ value: null, disabled: false }, Validators.compose([Validators.required])),
        'Description': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'ActiveFlag': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'SupportTypeCode': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
        'CreateDate': new FormControl({ value: "", disabled: true }),
        'CreateName': new FormControl({ value: "", disabled: true }),
        'UpdateDate': new FormControl({ value: "", disabled: true }),
        'UpdateName': new FormControl({ value: "", disabled: true }),
      });

    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Code != null) {
      this.paramCode = this.masterObj?.resolverData?.Code;
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.formZone.get("ActiveFlag").setValue(true);
    }

  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/department-management']);
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";

    if (!this.formZone.valid) {
 
      return;
    }

    if (this.paramCode) {
      requestObj["CodeEncoded"] = this.paramCode;
    }

    this.MasterData.checkDepartment(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveDepartment(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/department-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )

  }

}
