import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-block-cost-vessel',
  templateUrl: './block-cost-vessel.component.html',
  styleUrls: ['./block-cost-vessel.component.css']
})
export class BlockCostVesselComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  @Input() blockVesselMonthList: any[];
  @Input() formZone: FormGroup;
  @Input() freeze: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() abStage: boolean;
  @Input() canUpdateActualCost: boolean;
  @Input() canEditABFlag: boolean;
  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {

  }

  summaryMgoCost() {
    const self = this;

    var result = 0;
    this.blockVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["MgoCostAb"];
      } else {
        result += data["MgoCostCf"];
      }
      
    })
    return result;
  }




  summaryMgoActual() {
    var result = 0;
    const self = this;
    this.blockVesselMonthList.forEach(function (data) {
      if (self.formZone.get(data["fromActualMgoCostAb"]).value != null) {
        result += parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
      } else {
        result += 0;
      }
    });

    return result;
  }


  summaryReductionVesselCost() {
    const self = this;

    var result = 0;
    this.blockVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }

    })
    return result;
  }

  summaryReductionMgoCost() {
    const self = this;

    var result = 0;
    this.blockVesselMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageMgoAb"];
      } else {
        result += data["ReductionPercentageMgoCf"];
      }

    })
    return result;
  }
  




  summaryVesselActual() {
    var result = 0;
    const self = this;
    this.blockVesselMonthList.forEach(function (data) {

      if (self.formZone.get(data["fromActualVesselCostAb"]).value != null) {
        result += parseFloat(self.formZone.get(data["fromActualVesselCostAb"]).value);
      } else {
        result += 0;
      }

     // result += parseFloat(self.formZone.get(data["fromActualVesselCostAb"]).value);
    });

    return result;
  }


 

  summaryVesselCost() {
    var result = 0;
    const self = this;
    this.blockVesselMonthList.forEach(function (data) {
 
      if (self.abStage) {
        result += data["VesselCostAb"];
      } else {
        result += data["VesselCostCf"];
      }
    })
    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formZone = new FormGroup({});
    this.loadCost();
  }

  loadCost() {
    if (this.blockVesselMonthList.length > 0) {
      for (var i = 0; i < this.blockVesselMonthList.length; i++) {
        var costObj = this.blockVesselMonthList[i];
        this.formZone.addControl("fromActualMgoCostAb_" + i, new FormControl({ value: costObj.ActualMgoCostAb ? costObj.ActualMgoCostAb : "0.00", disabled: (this.freeze && !this.canFreeze) || !this.canUpdateActualCost || (!this.canEditABFlag && this.abStage)}));
        this.formZone.addControl("fromActualVesselCostAb_" + i, new FormControl({ value: costObj.ActualVesselCostAb ? costObj.ActualVesselCostAb : "0.00", disabled: (this.freeze && !this.canFreeze) || !this.canUpdateActualCost || (!this.canEditABFlag && this.abStage)}));

        this.formZone.updateValueAndValidity();
        costObj['fromActualMgoCostAb'] = "fromActualMgoCostAb_" + i;
        costObj['fromActualVesselCostAb'] = "fromActualVesselCostAb_" + i;

      }
    }
  }

  save() {

    const self = this;

    this.blockVesselMonthList.forEach(function (data) {
      data["ActualVesselCostAb"] = parseFloat(self.formZone.get(data["fromActualVesselCostAb"]).value);
      data["ActualMgoCostAb"] = parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateBlockVesselActual(this.blockVesselMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('Vessel Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

}
