import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
 
import { MatDialog } from '@angular/material/dialog';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-block-cost-rig',
  templateUrl: './block-cost-rig.component.html',
  styleUrls: ['./block-cost-rig.component.css']
})
export class BlockCostRigComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() blockRigMonthList: any[];
  @Input() formZone: FormGroup;
  @Input() freeze: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() abStage: boolean;
  @Input() canUpdateActualCost: boolean;
  @Input() canEditABFlag: boolean;

  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
  //  alert(this.canUpdateActualCost)
  }

  summary() {
    var result = 0;
    const self = this;
    this.blockRigMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["MgoCostAb"];
      } else {
        result += data["MgoCostCf"];
      }
      
    })
    return result;
  }

  summaryActual() {
    var result = 0;
    const self = this;
    this.blockRigMonthList.forEach(function (data) {
      if (self.formZone.get(data["fromActualMgoCostAb"]).value != null) {
        result += parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
      } else {
        result += 0;
      }
    });

    return result;
  }

  summaryReductionMgoCost() {
    const self = this;

    var result = 0;
    this.blockRigMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageMgoAb"];
      } else {
        result += data["ReductionPercentageMgoCf"];
      }

    })
    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formZone = new FormGroup({});
    this.loadCost();
  }

  loadCost() {
    if (this.blockRigMonthList.length > 0) {
      for (var i = 0; i < this.blockRigMonthList.length; i++) {
        var costObj = this.blockRigMonthList[i];
        this.formZone.addControl("fromActualMgoCostAb_" + i, new FormControl({ value: costObj.ActualMgoCostAb ? costObj.ActualMgoCostAb : "0.00", disabled: (this.freeze && !this.canFreeze) || !this.canUpdateActualCost || (!this.canEditABFlag && this.abStage) }));
        this.formZone.updateValueAndValidity();
        costObj['fromActualMgoCostAb'] = "fromActualMgoCostAb_" + i;
        
      }
    }
  }

  save() {

    const self = this;

    this.blockRigMonthList.forEach(function (data) {

      data["ActualMgoCostAb"] = parseFloat(self.formZone.get(data["fromActualMgoCostAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateBlockRigActual(this.blockRigMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('Rig Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

}
