import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare var pagingInputInit: any;
import { thDatatables } from '../../_helpers/thDatatables';
import { MasterDataService } from '../../_services/MasterData.service';
import { CommonUtilService } from '../../_helpers/CommonUtilService';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-cost-element-configuration',
  templateUrl: './cost-element-configuration.component.html',
  styleUrls: ['./cost-element-configuration.component.css']
})
export class CostElementConfigurationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private MasterData: MasterDataService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  @Input() formZone: FormGroup;

  masterObj: any;

  isReadonly: boolean = false;

  yearList: any[] = [];

  startYearList: any[] = [];

  endYearList: any[] = [];

  operationSupportList: any[] = [];

  summaryMethodList: any[] = [];

  stageList: any[] = [];

  activeList: any[] = [
    {
      Code: "Y",
      Description: "Active"
    },
    {
      Code: "N",
      Description: "Inactive"
    }
  ];

  allocationList: any[] = [{
    Code: "Y",
    Description: "Yes"
  }, {
    Code: "N",
    Description: "No"
  }];

  detail: any[] = [
    /*{
      seqnum: "1",
      OperationSupportCode: "Rig",
      SummaryMethodCode: "MGO Budget",
      StartYear: "2023",
      EndYear: "2025",
      StageCode: "AB and CF",
      ActiveFlag: "Active",
      UpdateDttm: "18/07/2022 00:00",
      UpdateName: "Admin"
    },
    {
      seqnum: "2",
      OperationSupportCode: "Vessel",
      SummaryMethodCode: "MGO Budget",
      StartYear: "2023",
      EndYear: "2025",
      StageCode: "AB and CF",
      ActiveFlag: "Active",
      UpdateDttm: "18/07/2022 00:00",
      UpdateName: "Admin"
    },
    {
      seqnum: "3",
      OperationSupportCode: "Vessel",
      SummaryMethodCode: "Vessel Budget",
      StartYear: "2023",
      EndYear: "2025",
      StageCode: "AB and CF",
      ActiveFlag: "Active",
      UpdateDttm: "18/07/2022 00:00",
      UpdateName: "Admin"
    },
    {
      seqnum: "4",
      OperationSupportCode: "Helicopter",
      SummaryMethodCode: "Budget",
      StartYear: "2023",
      EndYear: "2024",
      StageCode: "AB",
      ActiveFlag: "Active",
      UpdateDttm: "18/07/2022 00:00",
      UpdateName: "Admin"
    },
    {
      seqnum: "4",
      OperationSupportCode: "Helicopter",
      SummaryMethodCode: "Budget",
      StartYear: "2025",
      EndYear: "2025",
      StageCode : "AB",
      ActiveFlag: "Active",
      UpdateDttm: "18/07/2022 00:00",
      UpdateName: "Admin"
    },
    {
      seqnum: "6",
      OperationSupportCode: "Helicopter",
      SummaryMethodCode: "Budget",
      StartYear: "2023",
      EndYear: "2025",
      StageCode: "CF",
      ActiveFlag: "Active",
      UpdateDttm: "18/07/2022 00:00",
      UpdateName: "Admin"
    }*/
  ]


  dtOptions: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {

    var page = "CE"
    const user = this.authenticationService.userValue;
    if (user) {
      var authorizedCode = CommonUtilService.getPageAuthorized(page, user);
      this.isReadonly = authorizedCode == 'V';
    }

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );

    this.formZone = this.formBuilder.group({
      'OperationSupportCode': new FormControl(null),
      'SummaryMethodCode': new FormControl(null),
      'StartYear': new FormControl(null),
      'EndYear': new FormControl(null),
      'StageCode': new FormControl(null),
      'ActiveFlag': new FormControl(null),
      'AllocationPercentage': new FormControl(null)
    });

    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 11; i++) {
      if (i == 5) {
        this.yearList.push({ Code: currentYear });
      }
      else if (i < 5) {
        this.yearList.push({ Code: currentYear - (5 - i) });
      }
      else if (i > 5) {
        this.yearList.push({ Code: currentYear + (i - 5) });
      }
    }

    this.startYearList = this.yearList;
    this.endYearList = this.yearList;

    console.log("this.masterObj");
    console.log(this.masterObj);

    this.operationSupportList = this.masterObj.tcOperationSupport;
    this.summaryMethodList = this.masterObj.tcSummaryMethod;
    this.stageList = this.masterObj.tcStage;

    pagingInputInit();
    this.dtOptions = {
      pagingType: 'input',
      dom: 'B<"float-right"l>tip',
      language: thDatatables,
      processing: true,
      serverSide: true,
      responsive: true,
      scrollX: true,
      autoWidth: true,
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100],
      //Configure the buttons
      buttons: [
        {
          text: '+ New',
          className: 'mat-focus-indicator btn-green mat-raised-button mat-button-base _mat-animation-noopable button-margin-bottom',
          action: () => {
            this.isAdd();
          },
          init: function (api, node, config) {
            $(node).removeClass('dt-button')
          }
        }
      ],
      "columns": [
        {
          data: null,
          className: 'dt-head-center dt-body-center',
          width: '10px',
          render: this.renderAction
        },
        {
          data: 'seqnum',
          className: 'dt-head-center dt-body-center',
          "width": "10px"
        },
        {
          data: 'Operation_Support_Name',
          sortProperty: 'r.Operation_Support_Code',
          className: 'dt-head-center dt-body-center',
          "width": "50px",
        },
        {
          data: 'Summary_Method_Name',
          sortProperty: 'r.Summary_Method_Code',
          className: 'dt-head-center dt-body-center',
          "width": "125px"
        },
        {
          data: 'Start_Year',
          sortProperty: 'r.Start_Year',
          className: 'dt-head-center dt-body-center',
          "width": "125px"
        },
        {
          data: 'End_Year',
          sortProperty: 'r.End_Year',
          className: 'dt-head-center dt-body-center',
          width: '50px'
          //render: this.activeFlagData
        },
        {
          data: 'Stage_Name',
          sortProperty: 'r.Stage_Code',
          className: 'dt-head-center dt-body-center',
          width: '100px'
          //render: CommonUtilService.formatDateTime
        },
       /* {
          data: 'Allocation_Percentage',
          className: 'dt-head-center dt-body-center',
          width: '100px'
        },*/
        {
          data: 'Active_Flag',
          sortProperty: 'r.Active_Flag',
          className: 'dt-head-center dt-body-center',
          width: '100px',
          render: this.activeFlagData
        },
        {
          data: 'Update_Dttm',
          sortProperty: 'r.Update_Dttm',
          className: 'dt-head-center dt-body-center',
          width: '100px',
          render: CommonUtilService.formatDateTime
        },
        {
          data: 'Update_Name',
          sortProperty: 'r.Update_Name',
          className: 'dt-head-center dt-body-center',
          width: '100px'
        }
      ],
      columnDefs: [
        {
          "targets": [0, 1],
          "orderable": false
        }
      ],
      order: [[2, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        var criteria = {
          'start': dataTablesParameters.start,
          'length': dataTablesParameters.length,
          "sorterProperty": (this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty) ?
            this.dtOptions.columns[dataTablesParameters.order[0].column].sortProperty :
            this.dtOptions.columns[dataTablesParameters.order[0].column].data,
          "direction": dataTablesParameters.order[0].dir,
          "OperationSupportCode": this.formZone.get("OperationSupportCode").value,
          "SummaryMethodCode": this.formZone.get("SummaryMethodCode").value,
          "StartYear": this.formZone.get("StartYear").value,
          "EndYear": this.formZone.get("EndYear").value,
          "StageCode": this.formZone.get("StageCode").value,
          "ActiveFlag": (this.formZone.get("ActiveFlag").value == "Y") ? true : (this.formZone.get("ActiveFlag").value == "N") ? false : null,
        }
        this.MasterData.searchCostElementConfigurationPaging(criteria).subscribe(res => {
          setTimeout(() => {
            if (this.isReadonly) {
              this.dtOptions.buttons = [];
              $("div.dt-buttons").hide();
            }
            for (var i = 0; i < res.detail.length; ++i) {
              res.detail[i]['isReadonly'] = this.isReadonly;
            }
            callback({
              recordsTotal: res.iTotalRecords,
              iTotalDisplayRecords: res.iTotalDisplayRecords,
              recordsFiltered: res.recordsFiltered,
              data: res.detail
            }, 300);
          });
        });
      },
      createdRow: (row: any, data: any | Object, index: number) => {
        const self = this;

        row.querySelectorAll('.mat-edit').forEach(function (item) {
          item.addEventListener('click', function ($event) {
            self.router.navigate(['/cost-element-configuration-modal', data.IdEncoded, "edit"]);
          });
        });

        row.querySelectorAll('.mat-view').forEach(function (item) {
          item.addEventListener('click', function ($event) {
            self.router.navigate(['/cost-element-configuration-modal', data.IdEncoded, "view"]);
          });
        });
      }
    }
  }

  isSearch() {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
  }

  isClear() {
    this.formZone.get("OperationSupportCode").setValue(null);
    this.formZone.get("SummaryMethodCode").setValue(null);
    this.formZone.get("StartYear").setValue(null);
    this.formZone.get("EndYear").setValue(null);
    this.formZone.get("StageCode").setValue(null);
    this.formZone.get("ActiveFlag").setValue(null);
    this.isSearch();
  }

  isAdd() {
    this.router.navigate(['/cost-element-configuration-modal', 0, "add"]);
  }

  activeFlagData(data) {
    if (data == true) {
      return "Active";
    }
    return "Inactive";
  }

  renderAction(data, type, full, meta) {
    if (!data.isReadonly) {
      return '<mat-icon role="img"  matripple="" class="gray-icon mat-icon notranslate mat-ripple material-icons mat-edit" style="cursor: pointer;" aria-hidden="true" ng-reflect-color="primary" ng-reflect-radius="15" title="Edit">edit</mat-icon>'
    } else {
      return '<mat-icon-button _ngcontent-obq-c298="" role="img" color="primary" matripple="" class="mat-icon notranslate mat-ripple gray-icon material-icons mat-view" style="cursor: pointer;" aria-hidden="true" ng-reflect-color="primary" ng-reflect-radius="15" title="View">search</mat-icon-button>'
    }
  }

}
