import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignMarineSupportModalComponent } from './campaign-marine-support-modal/campaign-marine-support-modal.component';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CampaignService } from '../../_services/Campaign.service';
import { ConfirmModalComponent } from '../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-campaign-marine-support',
  templateUrl: './campaign-marine-support.component.html',
  styleUrls: ['./campaign-marine-support.component.css']
})
export class CampaignMarineSupportComponent implements OnInit {

 

  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  @Input() campaignMarineSupportMonthList: any[];
  @Input() CampaignName: string;
  @Input() formZone: FormGroup;
  @Output() childEvent = new EventEmitter();
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() canUpdateCost: boolean;
  @Input() canEditABFlag: boolean;
  ngOnInit(): void {
  }



  ngOnChanges(changes: SimpleChanges) {
    this.formZone = new FormGroup({});
    this.loadCost();

  }

  loadCost() {
    if (this.campaignMarineSupportMonthList.length > 0) {
      for (var i = 0; i < this.campaignMarineSupportMonthList.length; i++) {
        // let costObj: any;

        var costObj = this.campaignMarineSupportMonthList[i];
        //   alert(costObj['ActualAb'])
      //  this.formZone.addControl("fromActualAb_" + i, new FormControl(costObj['ActualAb']));
        this.formZone.addControl("fromActualAb_" + i, new FormControl({ value: costObj.ActualAb, disabled: this.freeze }));

        this.formZone.updateValueAndValidity();
        costObj['fromActualAb'] = "fromActualAb_" + i;

      }
    }
  }

  summary() {
    var result = 0;
    const self = this;
    this.campaignMarineSupportMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["BudgetAb"];
      } else {
        result += data["BudgetCf"];
      }
      
    })
    return result;
  }

  summaryActual() {
    var result = 0;
    const self = this;
    this.campaignMarineSupportMonthList.forEach(function (data) {

      result += parseFloat(self.formZone.get(data["fromActualAb"]).value);
    });

    return result;
  }

  summaryReduction() {
    const self = this;
    var result = 0;
    this.campaignMarineSupportMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }
    })
    return result;
  }

  save() {

    const self = this;

    this.campaignMarineSupportMonthList.forEach(function (data) {

      data["ActualAb"] = parseFloat(self.formZone.get(data["fromActualAb"]).value);
    });


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateMarineSupportActual(this.campaignMarineSupportMonthList).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.childEvent.emit('rig Actual update');
                //  this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }


  openUpdateCost(id, ParameterName) {

    this.campaignService.viewMarineSupportMonth(id).subscribe(
      result => {
    const alertDialog = this._matDialog.open(CampaignMarineSupportModalComponent, {
      //panelClass: 'select-reviewer-modal',
      disableClose: true,
      maxWidth: undefined,
      height: '1200Px',
      width: '1000px',
      data: {
        CampaignName: this.CampaignName,
        ParameterName: ParameterName,
        resolverData: result.resolverData,
        freeze: this.freeze,
        abStage: this.abStage,
        canFreeze: this.canFreeze,
        isReadonly: this.isReadonly,
        canUpdateCost: this.canUpdateCost,
        canEditABFlag: this.canEditABFlag
      }
    });

    alertDialog.afterClosed()
      .subscribe(response => {
        if (response != 'Close') {
          this.childEvent.emit('MarineSupport update');
        }
      });
      }
      , error => console.error(error));
  }
}
