import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../../_services/MasterData.service';

@Component({
  selector: 'app-cost-element-configuration-modal-detail',
  templateUrl: './cost-element-configuration-modal-detail.component.html',
  styleUrls: ['./cost-element-configuration-modal-detail.component.css']
})
export class CostElementConfigurationModalDeatilComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,
  ) { }

  @Input() formZone: FormGroup
  dtOptions: any = {};
  isReadonly: boolean = false;
  isSymbol: boolean = false;
  additionClick: boolean = false;
  subtractionClick: boolean = false;
  multiplicationClick: boolean = false;
  divisionClick: boolean = false;
  parenthesesOpenClick: boolean = false;
  parenthesesCloseClick: boolean = false;
  symbol: any;
  paramId: any;
  pageMode: any;
  masterObj: any;
  paramCode: any;

  operationSupportList: any[] = [];

  operationSupporCostTypetList: any[] = [];

  costTypeList: any[] = [];

  costList: any[] = [];

  blockList: any[] = [];

  summaryMethodList: any[] = [];

  ngOnInit(): void {

    this.operationSupporCostTypetList = this._data.resolve.tcrOperationSupportCostType.filter(x => x.OperationSupportCode == this._data.operationSupportCode);

    this.costList = this._data.resolve.tmCost.filter((item) => {

      return this.operationSupporCostTypetList.findIndex(x => x.CostTypeCode == item.CostTypeCode) != -1;

    });

    if (this._data.operationSupportCode == "TH") {
      this.costList = this.costList.filter((x) => x.BudgetTypeCode == "SS");
    } else if (this._data.operationSupportCode == "PR") {
      this.costList = this.costList.filter((x) => x.BudgetTypeCode == "SN");
    }


    this.costTypeList = this._data.resolve.tcCostType;
    this.operationSupportList = this._data.resolve.tcOperationSupport;
    this.summaryMethodList = this._data.resolve.tcSummaryMethod;
    this.blockList = this._data.resolve.tcBlock.filter(x => x.ActiveFlag);

    this.formZone = this.formBuilder.group({
      'CostId': new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      'OperationSupportCode': new FormControl({ value: "", disabled: true }, Validators.compose([Validators.required])),
      'AllocationCode': new FormControl({ value: "", disabled: false }),
      'ActiveFlag': new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
    });
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  save() {
    this.formZone.markAllAsTouched();

    console.log("save");
    let allocationName = "";

    if (this.formZone.get("AllocationCode").value) {
      allocationName = this.blockList.find(x => x.Code == this.formZone.get("AllocationCode").value).BlockName;
    }

    var requestObj = this.formZone.getRawValue();
    requestObj.CostName = this.costList.find(x => x.Id == this.formZone.get("CostId").value).CostName;
    requestObj.OperationSupportName = this.costTypeList.find(x => x.Code == this.formZone.get("OperationSupportCode").value).Description;
    requestObj.AllocationName = allocationName;

    if (this.isSymbol) {

      if (this.symbol) {
        requestObj.Symbol = this.symbol;
      } else {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: 'Please select Symbol.',
            buttonText: {
              cancel: 'OK'
            }
          }
        });
        return;
      }

    }

    let allocationCode = (this.formZone.get("AllocationCode").value == null || this.formZone.get("AllocationCode").value == "") ? "ALL" : this.formZone.get("AllocationCode").value;
    let costId = this.formZone.get("CostId").value;

    if (this._data.costItemList.length > 0) {

      let costAllocationAllItems = (this._data.costItemList as FormArray).controls.filter(
        x => x.get("BlockCode").value == "ALL"
      );

      let indexAll = costAllocationAllItems.findIndex(
        x => {
          let costIdData = x.get("CostId").value;
          return costIdData == costId;
        }
      );

      let index = this._data.costItemList.controls.findIndex(
        x => {
          let allocationCodeData = x.get("BlockCode").value;
          let costIdData = x.get("CostId").value;
          return (allocationCodeData == allocationCode && costIdData == costId);
        }
      );

      if (index != -1 || indexAll != -1) {
        const alertDialog = this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: 'Cost Name is duplicated',
            buttonText: {
              cancel: 'OK'
            }
          }
        });
        return;
      }
    }

    if (allocationCode != "ALL" && this._data.costItemList.length == 0) {
      const alertDialog = this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'Should be added formula for ALL before adding the formula for each block level',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    let costItemlatestIndex = this._data.costItemList.length - 1;

    if (costItemlatestIndex != -1) {
      let costItemlatest = this._data.costItemList.at(costItemlatestIndex);

      let allocationCode = (this.formZone.get("AllocationCode").value == null || this.formZone.get("AllocationCode").value == "") ? "ALL" : this.formZone.get("AllocationCode").value;

      let costItemFindAlloction = (this._data.costItemList as FormArray).controls.filter(
        x => x.get("BlockCode").value != "ALL"
      );

      if (costItemFindAlloction.length > 0 && allocationCode == "ALL") {
        const alertDialog = this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: 'The allocation for “ALL” could not be added. Please ensure that allocation is correctly.',
            buttonText: {
              cancel: 'OK'
            }
          }
        });
        return;
      }

      /*if (costItemlatest.get('BlockCode').value != allocationCode && costItemFindAlloction) {
        const alertDialog = this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: 'Allocation name does exist.Please add the allocation name similar to last record or add new allocation.',
            buttonText: {
              cancel: 'OK'
            }
          }
        });
        return;
      }*/
    }



 

    if (!this.formZone.valid) {
     /* var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }*/
      this.matDialogRef.close(requestObj);
    }
  }

  cancelCreate() {
    this.matDialogRef.close('close')
  }

  costChangeValue() {
    if (this.costTypeList && this.costTypeList.length > 0) {

      let item = this.formZone.get("CostId").value

      this.formZone.get("OperationSupportCode").setValue(this.costList.find(x => x.Id == item).CostTypeCode);
    }
  }

  allocationChangeValue() {

    let formulaItemList = null;

    if (this._data.formulaItemList && this._data.formulaItemList.length > 0) {

      let item = this.formZone.get("AllocationCode").value

      formulaItemList = this._data.formulaItemList.controls.find(
        x => x.get("BlockCode").value == item
      );
      if (formulaItemList != null || (item == '' || item == null)) {
        this.isSymbol = false;
      } else {
        this.isSymbol = true;
      }
    }
  }
  operationClick(symbol) {
    this.symbol = symbol;

    this.additionClick = false;
    this.subtractionClick = false;
    this.multiplicationClick = false;
    this.divisionClick = false;
    this.parenthesesOpenClick = false;
    this.parenthesesCloseClick = false;

    switch (symbol) {
      case "+":
        this.additionClick = true;
        break;
      case "-":
        this.subtractionClick = true;
        break;
      case "*":
        this.multiplicationClick = true;
        break;
      case "/":
        this.divisionClick = true;
        break;
      case "(":
        this.parenthesesOpenClick = true;
        break;
      case ")":
        this.parenthesesCloseClick = true;
        break;
      default:
      // code block
    }

  }
}
