import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../../_services/Campaign.service';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { CalculateCostService } from '../../../_helpers/CalculateCostService';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
@Component({
  selector: 'app-campaign-extra-budget-modal',
  templateUrl: './campaign-extra-budget-modal.component.html',
  styleUrls: ['./campaign-extra-budget-modal.component.css']
})
export class CampaignExtraBudgetModalComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    //   private userService: UserService,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }
    @Input() formZone: FormGroup;
    freeze: boolean;
    abStage: boolean;
    canFreeze: boolean;
    isReadonly: boolean;
  canUpdateCost: boolean;
  canEditABFlag: boolean;
   campaignExtraBudgetMonth: any;

 
  

  ngOnInit(): void {

    this.campaignExtraBudgetMonth = this._data.resolverData;
    this.freeze = this._data.freeze;
    this.abStage = this._data.abStage;
    this.canFreeze = this._data.canFreeze;
    this.isReadonly = this._data.isReadonly;
    this.canUpdateCost = this._data.canUpdateCost;
    this.canEditABFlag = this._data.canEditABFlag;
    
    this.formZone = new FormGroup({
      rigName: new FormControl({ value: this.campaignExtraBudgetMonth.ExtraBudget, disabled: true }),
      campaignName: new FormControl({ value: this._data.CampaignName, disabled: true }),
      month: new FormControl({ value: CommonUtilService.getMonthShortNameByCode(this.campaignExtraBudgetMonth.MonthCode), disabled: true }),
      year: new FormControl({ value: this.campaignExtraBudgetMonth.Year, disabled: true }),
      VesselTripOffshore: new FormControl({ value: this.abStage ? this.campaignExtraBudgetMonth.VesselTripOffshoreAb : this.campaignExtraBudgetMonth.VesselTripOffshoreCf, disabled: ((this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage)) }),
      VesselTripTka: new FormControl({ value: this.abStage ? this.campaignExtraBudgetMonth.VesselTripTkaAb : this.campaignExtraBudgetMonth.VesselTripTkaCf, disabled: ((this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage)) })
    });



    this.loadCost();
  }

  loadCost() {

    for (var i = 0; i < this.campaignExtraBudgetMonth.campaignExtraBudgetMonthCostList.length; i++) {
      var costObj = this.campaignExtraBudgetMonth.campaignExtraBudgetMonthCostList[i];
      this.formZone.addControl("fromValueCf_" + i, new FormControl({ value: this.abStage ? costObj.ValueAb : costObj.ValueCf, disabled: (costObj.VariableTypeCode == 'D' || costObj.AllocateFlag || (this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage)) }, Validators.compose([Validators.required])));
      this.formZone.updateValueAndValidity();
      costObj["fromValueCf"] = "fromValueCf_" + i;

     // this.countingList++;

    }
  }

  formatDateTime(UpdateDttm) {
    return CommonUtilService.formatDateTime(UpdateDttm)
  }

  calculateCost() {
    const self = this;
    this.campaignExtraBudgetMonth.campaignExtraBudgetMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);

    });

    const calObj = new CalculateCostService();
   
    calObj.noVesselTripOffshore = parseFloat(self.formZone.get("VesselTripOffshore").value);
    calObj.noVesselTripTka = parseFloat(self.formZone.get("VesselTripTka").value);

    calObj.calculateCostList(this.campaignExtraBudgetMonth.campaignExtraBudgetMonthCostList, this.campaignExtraBudgetMonth.costParentDtoList);
    this.campaignExtraBudgetMonth.campaignExtraBudgetMonthCostList.forEach(function (data) {

      self.formZone.get(data["fromValueCf"]).setValue(data["ValueCf"]);
    });
  }


  save() {

    const self = this;
    this.campaignExtraBudgetMonth.VesselTripOffshoreCf = self.formZone.get("VesselTripOffshore").value;
    this.campaignExtraBudgetMonth.VesselTripTkaCf = self.formZone.get("VesselTripTka").value;

    this.campaignExtraBudgetMonth.campaignExtraBudgetMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);
      if (self.abStage) {
        data["ValueAb"] = data["ValueCf"];
      }
    });
    this.campaignExtraBudgetMonth.AbFlag = this.abStage;



    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateCampaignExtraBudgetMonth(this.campaignExtraBudgetMonth).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

}
