import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';

@Component({
  selector: 'app-extra-budget-management-modal',
  templateUrl: './extra-budget-management-modal.component.html',
  styleUrls: ['./extra-budget-management-modal.component.css']
})
export class ExtraBudgetManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  costList = [];
  allocationList = [];
  costDtoList: any[] = [];
  costTypeCodeList: any[];
  costNameList: any[] = [];
  selectedCostNameList: any[] = [];
  tcrBudgetCostTypeList: any[] = [];
  tcBudgetTypeList: any[] = [];
  extraBudgetCostList: any[] = [];
  allocationYearList: any[] = [];
  selectedExtraBudgetCode: any;
  checkAllocation: boolean;
  isReadonly: boolean = false;
  costCountingLink = 0;
  allocationCountingLink = 0;
  hideDeleteBtn = 0;
  masterObj: any;
    paramId: any;
    pageMode: any;
    blockList: any;
  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  yearList: any[] = [/*{
    Code: "2016",
  }, {
    Code: "2017",
  }, {
    Code: "2018",
  }, {
    Code: "2019",
  }, {
    Code: "2020",
  }, {
    Code: "2021",
  }, {
    Code: "2022",
  }, {
    Code: "2023",
  }, {
    Code: "2024",
  }, {
    Code: "2025",
  }, {
    Code: "2026",
  }*/];

  costDtoPototype: any[] = [
    {
      Block: 'ALL',
      CostType: "FC",
      CostId: 81,
      Value: "8",
      Status: true,
      UpdateDate: "2021-04-22 13:39:39.117",
      UpdateBy: "System Admin"
    },
    {
      Block: 'ALL',
      CostType: "FC",
      CostId: 82,
      Value: "3500",
      Status: true,
      UpdateDate: "2021-04-22 13:39:39.117",
      UpdateBy: "System Admin"
    },
    {
      Block: 'ALL',
      CostType: "FC",
      CostId: 84,
      Value: "1",
      Status: true,
      UpdateDate: "2021-04-22 13:39:39.117",
      UpdateBy: "System Admin"
    },
    {
      Block: 'ALL',
      CostType: "FC",
      CostId: 85,
      Value: "2000",
      Status: true,
      UpdateDate: "2021-04-22 13:39:39.117",
      UpdateBy: "System Admin"
    }
  ];

  allocationDtoPototype: any[] = [
    {
      Block: 'M9',
      Percentage: "90",
      Value: "15000",
      Status: true,
      StartDate: "2021-04-22 13:39:39.117",
      EndDate: "2021-05-22 13:39:39.117",
      UpdateDate: "2021-04-22 13:39:39.117",
      UpdateBy: "System Admin"
    },
    {
      Block: 'ALT',
      Percentage: "10",
      Value: "15",
      Status: true,
      StartDate: "2021-04-22 13:39:39.117",
      EndDate: "2021-05-22 13:39:39.117",
      UpdateDate: "2021-04-22 13:39:39.117",
      UpdateBy: "System Admin"
    },
  ];

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
    if (this.masterObj && this.masterObj != null) { 
        this.blockList = this.masterObj.tcBlock;
        this.costDtoList = this.masterObj.costListDto;
        this.costTypeCodeList = this.masterObj.tcCostType;
        this.tcrBudgetCostTypeList = this.masterObj.tcrBudgetCostType;
        this.tcBudgetTypeList = this.masterObj.tcBudgetType;
    }
    if (this.masterObj &&
      this.masterObj != null &&
      this.masterObj.resolverData &&
      this.masterObj.resolverData != null) {

        this.checkAllocation = this.masterObj.resolverData.AllocationFlag == true ? true : this.masterObj.resolverData.AllocationFlag == false ? false : false
    } else {
         this.checkAllocation = false;
    }
  
    var budgetCostFiltedList = this.tcrBudgetCostTypeList.filter(x => x.BudgetTypeCode == "EB");
    for (var budgetCostFilter of budgetCostFiltedList) {
      this.extraBudgetCostList.push(this.costTypeCodeList.find(x => x.Code == budgetCostFilter.CostTypeCode));
    }

    if (this.paramId == "0") {
      var costExistingValue = [];
      for (var costExisting of this.masterObj?.costExistingList) {
        costExistingValue.push(this.costTypeCodeList.find(x => x.Description == costExisting.Extra_Budget));
      }

      this.extraBudgetCostList = this.extraBudgetCostList.filter(x => !costExistingValue.includes(x));
    }

    this.formZone = this.formBuilder.group({
      'ExtraBudget': new FormControl({ value: '', disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'ActiveFlag': new FormControl({ value: true, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'CreateDate': new FormControl({ value: "", disabled: true }),
      'CreateName': new FormControl({ value: "", disabled: true }),
      'UpdateDate': new FormControl({ value: "", disabled: true }),
      'UpdateName': new FormControl({ value: "", disabled: true }),
      'ExtraBudgetCostList': this.formBuilder.array([]),
      'ExtraBudgetAllocationList': this.formBuilder.array([])
    });

    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 11; i++) {
      if (i == 5) {
        this.yearList.push({ Code: currentYear });
      }
      else if (i < 5) {
        this.yearList.push({ Code: currentYear - (5- i) });
      }
      else if (i > 5) {
        this.yearList.push({ Code: currentYear + (i-5) });
      }
    }

    if (this.masterObj?.resolverData?.ExtraBudget) {
      var extraBudget = this.costTypeCodeList.find(x => x.Description == this.masterObj?.resolverData?.ExtraBudget);
      if (extraBudget) {
        this.formZone.get("ExtraBudget").setValue(extraBudget.Code, { emitEvent: false });
      }
      
      this.formZone.get("ActiveFlag").setValue((this.masterObj?.resolverData?.ActiveFlag == true ? true : this.masterObj?.resolverData?.ActiveFlag == false ? false : null), { emitEvent: false });
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.ExtraBudgetCostList &&
      this.masterObj?.resolverData.ExtraBudgetCostList?.length > 0) {
      this.loadExtraBudgetCost(this.masterObj.resolverData.ExtraBudgetCostList);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.ExtraBudgetAllocationList &&
      this.masterObj?.resolverData.ExtraBudgetAllocationList?.length > 0) {
      this.loadExtraBudgetAllocation(this.masterObj.resolverData.ExtraBudgetAllocationList);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

  }

  addRigCost() {
    this.costItems.push(this.formBuilder.group({
      "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
    }));
  }

  addExtraBudgetCostWithData(data, editData) {
    this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == this.selectedExtraBudgetCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == data.DefaluteFlag));
    if (editData) {
      this.costItems.push(this.formBuilder.group({
        "CostTypeCode": new FormControl({ value: editData.CostTypeCode, disabled: (data.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
        "CostId": new FormControl({ value: editData.CostId, disabled: (data.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
        "Value": new FormControl({ value: editData.Value, disabled: (data.AllocateFlag || this.isReadonly) }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: editData.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required]))
      }));
    }
    else {
      this.costItems.push(this.formBuilder.group({
        "CostTypeCode": new FormControl({ value: data.CostTypeCode, disabled: (data.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
        "CostId": new FormControl({ value: data.Id, disabled: (data.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required])),
        "Value": new FormControl({ value: data.Value, disabled: (data.AllocateFlag || this.isReadonly) }, Validators.compose([Validators.required])),
        "ActiveFlag": new FormControl({ value: true, disabled: (data.DefaluteFlag) || this.isReadonly}, Validators.compose([Validators.required]))
      }));
    }
  }

  deleteRigCost(index) {
    this.costItems.removeAt(index);
    this.costNameList.splice(index, 1);
    this.selectedCostNameList.splice(index, 1);
  }

  loadExtraBudgetCost(extraBudgetCostList) {

    /*for (var i = 0; i < this.costDtoPototype.length; i++) {
      var costTypeObj = this.costDtoPototype[i];
      let x: any = {};

      if (!this.costDtoPototype) {
        this.costList = [];
      }
      if (this.paramId == "Takitta") {
        this.formZone.addControl("Block_" + i, new FormControl(costTypeObj.Block, Validators.compose([Validators.required])));
      }
      this.formZone.addControl("CostType_" + i, new FormControl(costTypeObj.CostType, Validators.compose([Validators.required])));
      this.formZone.addControl("CostName_" + i, new FormControl(costTypeObj.CostId, Validators.compose([Validators.required])));
      this.formZone.addControl("Value_" + i, new FormControl(costTypeObj.Value, Validators.compose([Validators.required])));
      this.formZone.addControl("Status_" + i, new FormControl(costTypeObj.Status == true ? true : costTypeObj.Status == false ? false : null, Validators.compose([Validators.required])));

      this.formZone.updateValueAndValidity();
      if (this.paramId == 'Takitta') {
        x["Block"] = "Block_" + this.costCountingLink;
      }
      x["CostType"] = "CostType_" + i;
      x["CostName"] = "CostName_" + i;
      x["Value"] = "Value_" + i;
      x["Status"] = "Status_" + i;
      x["CostNameList"] = [];
      this.costList.push(x);
      this.costTypeCodeChangeValue(i);
      this.costCountingLink++;

    }*/
    this.costNameList = [];
    this.selectedCostNameList = [];

    this.selectedExtraBudgetCode = this.formZone.get("ExtraBudget").value;
    for (var extraBudgetCost of extraBudgetCostList) {
      var costDtoFilter = this.costDtoList.find(x => x.CostTypeCode == this.selectedExtraBudgetCode && x.Id == extraBudgetCost.CostId);
      if (costDtoFilter) {
        this.addExtraBudgetCostWithData(costDtoFilter, extraBudgetCost);
        this.selectedCostNameList.push(costDtoFilter);
      }
    }

    this.hideDeleteBtn = this.selectedCostNameList.length;
    this.costTypeCodeList = this.masterObj.tcCostType.filter(x => x.Code == this.selectedExtraBudgetCode);
  }

  costTypeCodeChangeValue(idx) {
    if (this.costItems && this.costItems.length > 0) {
      var item = this.costItems.at(idx);
      this.costNameList[idx] = this.costDtoList.filter(x => (x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C'));
      //if (this.selectedCostNameList && this.selectedCostNameList.length > 0) {
      //  this.costNameList[idx] = this.costNameList[idx].filter(x => !this.selectedCostNameList.includes(x));
      //}
    }
  }

  costNameChangeValue(index) {
    //if (this.costItems && this.costItems.length > 0) {
    //  var item = this.costItems.at(index);
    //  var currentSelected = this.costDtoList.find(x => x.Id == item.get("CostId").value);
    //  if (this.selectedCostNameList[index]) {
    //    this.selectedCostNameList[index] = currentSelected;
    //  }
    //  else {
    //    this.selectedCostNameList.push(currentSelected);
    //  }

    //  for (var i = 0; i < this.costItems.length; i++) {
    //    if (i != index) {
    //      if (this.costNameList[i] && this.costNameList[i].length > 0) {
    //        //this.costNameList[i] = this.costNameList[i].filter(x => x.Id != currentSelected.Id);
    //        var itemRow = this.costItems.at(i);
    //        if (itemRow.get("CostId") && itemRow.get("CostId").value) {
    //          this.costNameList[i] = this.costNameList[i].filter(x => !this.selectedCostNameList.includes(x));
    //          this.costNameList[i].push(this.costDtoList.find(x => x.Id == itemRow.get("CostId").value));
    //        }
    //        /*var item = this.costItems.at(i);
    //        if (item.get("CodeId") && item.get("CodeId").value) {
    //          console.log(item.get("CodeId").value);
    //          this.costNameList[i].push(this.costDtoList.find(x => x.Id == item.get("CodeId").value));
    //        }*/
    //      }
    //    }
    //  }
    //}
  }

  addAllocation() {
    /*let x: any = {};

    if (!this.allocationList) {
      this.allocationList = [];
    }
    this.formZone.addControl("Block_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.addControl("Percentage_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.addControl("Value_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.addControl("Status_" + this.allocationCountingLink, new FormControl(true, Validators.compose([Validators.required])));
    this.formZone.addControl("StartDate_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.addControl("EndDate_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.addControl("UpdateDate_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.addControl("UpdateBy_" + this.allocationCountingLink, new FormControl("", Validators.compose([Validators.required])));
    this.formZone.updateValueAndValidity();
    x["Block"] = "Block_" + this.allocationCountingLink;
    x["Percentage"] = "Percentage_" + this.allocationCountingLink;
    x["Value"] = "Value_" + this.allocationCountingLink;
    x["Status"] = "Status_" + this.allocationCountingLink;
    x["StartDate"] = "StartDate_" + this.allocationCountingLink;
    x["EndDate"] = "EndDate_" + this.allocationCountingLink;
    x["UpdateDate"] = "UpdateDate_" + this.allocationCountingLink;
    x["UpdateBy"] = "UpdateBy_" + this.allocationCountingLink;
    this.allocationList.push(x);
    this.allocationCountingLink++;*/

    this.allocationYearList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
    this.allocationItems.push(this.formBuilder.group({
      "BlockCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Percentage": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
      "StartYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "EndYear": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "UpdateDate": new FormControl({ value: "", disabled: true }),
      "UpdateBy": new FormControl({ value: "", disabled: true })
    }));
  }

  startYearChangeValue(index) {
    var allocationItem = this.allocationItems.at(index);
    var startYear = allocationItem.get("StartYear").value;
    this.allocationYearList[index]["endYearList"] = this.yearList.filter(x => x.Code >= startYear);
    this.formZone.updateValueAndValidity();
  }

  endYearChangeValue(index) {
    var allocationItem = this.allocationItems.at(index);
    var endYear = allocationItem.get("EndYear").value;
    this.allocationYearList[index]["startYearList"] = this.yearList.filter(x => x.Code <= endYear);
    this.formZone.updateValueAndValidity();
  }

  //deleteAllocation(items, index) {
  deleteAllocation(index) {
    this.allocationItems.removeAt(index);
  }

  loadExtraBudgetAllocation(extraBudgetAllocationList) {
    /*for (var i = 0; i < this.allocationDtoPototype.length; i++) {
      var costTypeObj = this.allocationDtoPototype[i];
      let x: any = {};

      if (!this.allocationDtoPototype) {
        this.allocationList = [];
      }

      this.formZone.addControl("Block_" + i, new FormControl(costTypeObj.Block, Validators.compose([Validators.required])));
      this.formZone.addControl("Percentage_" + i, new FormControl(costTypeObj.Percentage, Validators.compose([Validators.required])));
      this.formZone.addControl("Value_" + i, new FormControl(costTypeObj.Value, Validators.compose([Validators.required])));
      this.formZone.addControl("Status_" + i, new FormControl(costTypeObj.Status == true ? true : costTypeObj.Status == false ? false : null, Validators.compose([Validators.required])));
      this.formZone.addControl("StartDate_" + i, new FormControl(costTypeObj.StartDate, Validators.compose([Validators.required])));
      this.formZone.addControl("EndDate_" + i, new FormControl(costTypeObj.EndDate, Validators.compose([Validators.required])));
      this.formZone.addControl("UpdateDate_" + i, new FormControl(CommonUtilService.formatDateTime(costTypeObj.UpdateDate), Validators.compose([Validators.required])));
      this.formZone.addControl("UpdateBy_" + i, new FormControl(costTypeObj.UpdateBy, Validators.compose([Validators.required])));
      this.formZone.updateValueAndValidity();

      x["Block"] = "Block_" + i;
      x["Percentage"] = "Percentage_" + i;
      x["Value"] = "Value_" + i;
      x["Status"] = "Status_" + i;
      x["StartDate"] = "StartDate_" + i;
      x["EndDate"] = "EndDate_" + i;
      x["UpdateDate"] = "UpdateDate_" + i;
      x["UpdateBy"] = "UpdateBy_" + i;
      this.allocationList.push(x);
      this.allocationCountingLink++;

    }*/
    if (extraBudgetAllocationList && extraBudgetAllocationList.length > 0) {
      for (var i = 0; i < extraBudgetAllocationList.length; i++) {
        var extraBudgetAllocationObj = extraBudgetAllocationList[i];
        this.allocationYearList.push({ "startYearList": this.yearList, "endYearList": this.yearList });
        this.allocationItems.push(this.formBuilder.group({
          "BlockCode": new FormControl({ value: extraBudgetAllocationObj.BlockCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "Percentage": new FormControl({ value: extraBudgetAllocationObj.Percentage, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: extraBudgetAllocationObj.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "StartYear": new FormControl({ value: parseInt(extraBudgetAllocationObj.StartYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "EndYear": new FormControl({ value: parseInt(extraBudgetAllocationObj.EndYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "UpdateDate": new FormControl({ value: CommonUtilService.formatDateTime(extraBudgetAllocationObj.UpdateDttm), disabled: this.isReadonly }),
          "UpdateBy": new FormControl({ value: extraBudgetAllocationObj.UpdateName, disabled: this.isReadonly })
        }));

        this.startYearChangeValue(i);
        this.endYearChangeValue(i);
      }
    }
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/extra-budget-management']);
  }

  get costItems(): FormArray {
    return this.formZone.get('ExtraBudgetCostList') as FormArray;
  }
  get allocationItems(): FormArray {
    return this.formZone.get('ExtraBudgetAllocationList') as FormArray;
  }

  extraBudgetChangeValue() {
    this.costNameList = [];
    this.selectedCostNameList = [];
    this.formZone.setControl('ExtraBudgetCostList', this.formBuilder.array([]));

    this.selectedExtraBudgetCode = this.formZone.get("ExtraBudget").value;
    var costDtoFilterList = this.costDtoList.filter(x => x.CostTypeCode == this.selectedExtraBudgetCode);
    for (var costDtoFilter of costDtoFilterList) {
      if (costDtoFilter.DefaluteFlag) {
        this.addExtraBudgetCostWithData(costDtoFilter, null);
        this.selectedCostNameList.push(costDtoFilter);
      }
    }

    this.hideDeleteBtn = this.selectedCostNameList.length;
    this.costTypeCodeList = this.masterObj.tcCostType.filter(x => x.Code == this.selectedExtraBudgetCode);
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";

    if (this.checkAllocation) {
      requestObj.AllocationFlag = true;
      if (this.allocationItems.length == 0) {
        message = "The data cannot be save. Because the allocation is null.";
      }
    }

    if (!this.formZone.valid || message) {
      var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }
      return;
    }

    
    if (this.paramId && this.paramId != "0") {
      requestObj["IdEncoded"] = this.paramId;
    }

    if (this.costItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the cost is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    this.MasterData.checkExtraBudgetCost(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveExtraBudget(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/extra-budget-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )
  }
}
