import { evaluate } from 'mathjs';
export class CalculateCostService {

 
  public _days: any = 0;
  public sumPaxPerMonth: any = 0;
  public estimateFlightHour: any = 0;
  public noVesselTripOffshore: any = 0;
  public noVesselTripTka: any = 0;
  public campaignMonth: any = 0;

  calculateCostList(colstList, costParentDtoList) {
    if (colstList && (Array.isArray(colstList))
      && costParentDtoList && (Array.isArray(costParentDtoList))) {
      const self = this;
      
      colstList.forEach(function (data) {
      //  alert(self._days)
       const constantList = [];

        if ("D" == data["VariableTypeCode"]) {
          

          const costCList = costParentDtoList.filter(x => x.ParentCostId == data["CostId"]);
      
          costCList.forEach(function (c) {
            constantList.push(colstList.filter(x => x.CostId == c["CostId"])[0]);
          });

         
          data["ValueCf"] = self.calculateValue(constantList, data["SpecialFlag"] ? data["SpecialFormula"]: data["Formula"], data["SpecialFlag"]);

       
        }
      });
    }
  }





  calculateValue(costConstatntList, Formula, specialFlag) {
    var result = 0;
    try {
     
      costConstatntList.forEach(function (data) {
      
        var CostName = data["CostName"]
        CostName = "(" + CostName.trim() + ")";
       
        const value = data["ValueCf"]
         
        Formula = Formula.replace(CostName, value);
        console.log(Formula);
      });
    
      if (specialFlag == true) {
        Formula = Formula.replace('[Day]', this._days);
        Formula = Formula.replace("[SUM_PAX_PER_MONTH]", this.sumPaxPerMonth);
        Formula = Formula.replace("[Estimate Flying hour]", this.estimateFlightHour);
        Formula = Formula.replace("[NO_VESSEL_TRIP_OFFSHORE]", this.noVesselTripOffshore);
        Formula = Formula.replace("[NO_VESSEL_TRIP_TKA]", this.noVesselTripTka);
        Formula = Formula.replace("[CAMPAIGN_MONTH]", this.campaignMonth);
        
      }
     //console.log(Formula)
      var ceilingModValue = 0;
      var ceilingFlag = this.isCeiling(Formula);
      if (ceilingFlag) {
        ceilingModValue = this.getCeilingModValue(Formula);
        Formula = this.getCeilingFormula(Formula);
      }
   
      
      
        Formula = Formula.replace("x", "*");
 
      result = evaluate(Formula);
    
     // result = eval(Formula);
    //  result = new Function('return ' + Formula)();
     // console.log(Formula)
      if (ceilingFlag) {
       // console.log('ceiling')
        result = this.Ceiling(result, ceilingModValue);
    //    console.log(result)
        }
     
    }
    catch (ex) {

      console.log(ex)
      return 0;  // Console.WriteLine(ex.Message);
    }

    return result;
  };

  isCeiling(Formula:string) {
    if (Formula.toUpperCase().indexOf("Ceiling".toUpperCase()) != -1) {
      return true;
    }
    return false;

  }

  getCeilingModValue(Formula: string) {
    Formula = Formula.replace("Ceiling" + "(", "");
    Formula = Formula.replace("Ceiling".toUpperCase() + "(", "");
    Formula = Formula.substring(0, Formula.length - 1);
    var Formulas = Formula.split(",");
    var result = parseFloat(Formulas[1]);
    return result;

  }

  getCeilingFormula(Formula: string) {
    Formula = Formula.replace("Ceiling" + "(", "");
    Formula = Formula.replace("Ceiling".toUpperCase() + "(", "");
    Formula = Formula.substring(0, Formula.length - 1);
    var Formulas = Formula.split(",");
    var result = Formulas[0];
    return result;

  }



  Ceiling(value, mod) {
    if (mod == 1) {
      return Math.ceil(value);
    }
    else {
      var subValue = value % mod;
      if (subValue != 0) {
        return (value - subValue) + mod;
      }
      else {
        return value;
      }

    }
  }



  isRound(Formula: string) {
    if (Formula.toUpperCase().indexOf("Round".toUpperCase()) != -1) {
      return true;
    }
    return false;

  }

  getRoundIndex(Formula: string) {
    Formula = Formula.replace("Round" + "(", "");
    Formula = Formula.replace("Round".toUpperCase() + "(", "");
    Formula = Formula.substring(0, Formula.length - 1);
    var Formulas = Formula.split(",");
    var result = parseInt(Formulas[1]);
    return result;

  }

  getRoundFormula(Formula: string) {
    Formula = Formula.replace("Round" + "(", "");
    Formula = Formula.replace("Round".toUpperCase() + "(", "");
    Formula = Formula.substring(0, Formula.length - 1);
    var Formulas = Formula.split(",");
    var result = Formulas[0];
    return result;

  }


  Round(value, index) {
    console.log(value)
    if (index == 0) {
      return this.RoundHalfDown(value);
    }
    else {
      return this.RoundByIndex(value, index);
    }
  }

  RoundHalfDown(num) {
    num = -Math.round(-num);
    console.log(num)
    return num
  }

  RoundByIndex(num,index) {
    var x = Math.pow(10, index);
   return -Math.round(-(num + Number.EPSILON) * x) / x
  }


}
