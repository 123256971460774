import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CampaignService } from '../../../_services/Campaign.service';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { CalculateCostService } from '../../../_helpers/CalculateCostService';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { evaluate } from 'mathjs';
@Component({
  selector: 'app-campaign-helicopter-modal',
  templateUrl: './campaign-helicopter-modal.component.html',
  styleUrls: ['./campaign-helicopter-modal.component.css']
})
export class CampaignHelicopterModalComponent implements OnInit {

  @Input() formZone: FormGroup;
  camopaignHelicopterMonth: any;
  freeze: boolean;
  abStage: boolean;
  countingList = 0;
  tcBlock: any;
  canFreeze: boolean;
  isReadonly: boolean;
  canUpdateCost: boolean;
  canEditABFlag: boolean;
  constructor(public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    //   private userService: UserService,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.camopaignHelicopterMonth = this._data.resolverData;
    this.abStage = this._data.abStage;
    this.freeze = this._data.freeze;
    this.tcBlock = this._data.tcBlock;
    this.canFreeze = this._data.canFreeze;
    this.isReadonly = this._data.isReadonly;
    this.canUpdateCost = this._data.canUpdateCost;
    this.canEditABFlag = this._data.canEditABFlag;
    this.formZone = new FormGroup({
      helicopterName: new FormControl({ value: this.camopaignHelicopterMonth.HelicopterName, disabled: true }),
      campaignName: new FormControl({ value: this._data.CampaignName, disabled: true }),
      month: new FormControl({ value: CommonUtilService.getMonthShortNameByCode(this.camopaignHelicopterMonth.MonthCode), disabled: true }),
      year: new FormControl({ value: this.camopaignHelicopterMonth.Year, disabled: true })
     
    });

    this.loadDetail();
  }

  loadDetail() {
    
    for (var i = 0; i < this.camopaignHelicopterMonth.campaignHelicopterMonthCostList.length; i++) {
      var costObj = this.camopaignHelicopterMonth.campaignHelicopterMonthCostList[i];
      this.formZone.addControl("fromValueCf_" + i, new FormControl({ value: this.abStage ? costObj.ValueAb : costObj.ValueCf, disabled: (costObj.VariableTypeCode == 'D' || costObj.AllocateFlag || (this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage)) }, Validators.compose([Validators.required])));
      this.formZone.updateValueAndValidity();
      costObj["fromValueCf"] = "fromValueCf_" + i;

     // this.countingList++;

    }


    for (var i = 0; i < this.camopaignHelicopterMonth.campaignHelicopterMonthPaxPerMonthList.length; i++) {
      var paxPerMonthObj = this.camopaignHelicopterMonth.campaignHelicopterMonthPaxPerMonthList[i];
    //  this.formZone.addControl("paxPerMonth_" + i, new FormControl(paxPerMonthObj.PaxPerMonth));
      this.formZone.addControl("paxPerMonth_" + i, new FormControl({ value: this.abStage ? paxPerMonthObj.PaxPerMonthAb : paxPerMonthObj.PaxPerMonthCf, disabled: (this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage) }, Validators.compose([Validators.required])));



      this.formZone.updateValueAndValidity();
      paxPerMonthObj["formPaxPerMonth"] = "paxPerMonth_" + i;
 
    }




    for (var i = 0; i < this.camopaignHelicopterMonth.campaignHelicopterMonthFlyingList.length; i++) {
      var flyingMonthObj = this.camopaignHelicopterMonth.campaignHelicopterMonthFlyingList[i];
      //  this.formZone.addControl("paxPerMonth_" + i, new FormControl(paxPerMonthObj.PaxPerMonth));
      this.formZone.addControl("flyingMonth_" + i, new FormControl({ value: this.abStage ? flyingMonthObj.ValueAb : flyingMonthObj.ValueCf, disabled: (this.freeze && !this.canFreeze) || flyingMonthObj.Description != 'Average PAX/flight' || !this.canUpdateCost || (!this.canEditABFlag && this.abStage) }, Validators.compose([Validators.required])));


      flyingMonthObj.TimeValueCf = this.abStage ? flyingMonthObj.TimeValueAb : flyingMonthObj.TimeValueCf;
      this.formZone.updateValueAndValidity();
      flyingMonthObj["formflyingMonth"] = "flyingMonth_" + i;

    }
    
    
  }

  calculateCost() {
    const calObj = new CalculateCostService();
    const self = this;
    var sumPaxPerMonth = 0;

    this.camopaignHelicopterMonth.campaignHelicopterMonthPaxPerMonthList.forEach(function (data) {
 
      sumPaxPerMonth += parseFloat(self.formZone.get(data["formPaxPerMonth"]).value);

    });

    

   // console.log(sumPaxPerMonth)
    

    const campaignHelicopterAllocation = this.camopaignHelicopterMonth.campaignHelicopterAllocationList.find(x =>
      x.CampaignId == this.camopaignHelicopterMonth.CampaignId &&
      x.HelicopterId == this.camopaignHelicopterMonth.HelicopterId
    );

    if ((self.abStage ? campaignHelicopterAllocation.SumPaxPerMonthAb : campaignHelicopterAllocation.SumPaxPerMonthCf)
      != sumPaxPerMonth) {

      if (self.abStage) {
        campaignHelicopterAllocation.SumPaxPerMonthAb = sumPaxPerMonth * this.tcBlock.PaxMile;
        campaignHelicopterAllocation.SumPaxPerMonthCf = sumPaxPerMonth * this.tcBlock.PaxMile;
      } else {
        campaignHelicopterAllocation.SumPaxPerMonthCf = sumPaxPerMonth * this.tcBlock.PaxMile;
      }
      

      var foundIndex = this.camopaignHelicopterMonth.campaignHelicopterAllocationList.findIndex(x =>
        x.CampaignId == this.camopaignHelicopterMonth.CampaignId &&
        x.HelicopterId == this.camopaignHelicopterMonth.HelicopterId
      );

      this.camopaignHelicopterMonth.campaignHelicopterAllocationList[foundIndex] = campaignHelicopterAllocation;
     
      var SumPaxPerMonthAllAb = 0;
      var SumPaxPerMonthAllCf = 0;
      this.camopaignHelicopterMonth.campaignHelicopterAllocationList.forEach(function (data) {
      
        SumPaxPerMonthAllAb += data["SumPaxPerMonthAb"];
      
        SumPaxPerMonthAllCf += data["SumPaxPerMonthCf"];
        
      });

     
     
      //CostValue
      var index = 1;
      var sumPercentAb = 0;
      var sumPercentCf = 0;
      for (var i = 0; i < this.camopaignHelicopterMonth.campaignHelicopterAllocationList.length;i++){
      
        var campaignAllocation = this.camopaignHelicopterMonth.campaignHelicopterAllocationList[i];
       
        var percentAb = SumPaxPerMonthAllAb == 0 ? 0: (campaignAllocation.SumPaxPerMonthAb / SumPaxPerMonthAllAb) * 100;
        var percentCf = SumPaxPerMonthAllCf == 0 ? 0 :(campaignAllocation.SumPaxPerMonthCf / SumPaxPerMonthAllCf) * 100;
       
        //   percent = Math.Round(percent, 2);
        campaignAllocation.PercentageAb = Math.round((percentAb + Number.EPSILON) * 100) / 100;
        campaignAllocation.PercentageCf = Math.round((percentCf + Number.EPSILON) * 100) / 100;

        sumPercentAb += campaignAllocation.PercentageAb ? campaignAllocation.PercentageAb : 0;
        sumPercentCf += campaignAllocation.PercentageCf ? campaignAllocation.PercentageCf : 0;
       
        if (this.camopaignHelicopterMonth.campaignHelicopterAllocationList.length == index) {
          var diffAb = 100 - sumPercentAb;
          if (diffAb != 0) {
            campaignAllocation.PercentageAb += diffAb;
          }


          var diffCf = 100 - sumPercentCf;
          if (diffCf != 0) {
            
        //    console.log('diffCf  --> ' + diffCf)
        //    console.log('PercentageCf  --> ' + campaignAllocation.PercentageCf)
            campaignAllocation.PercentageCf += diffCf;
        //    console.log('PercentageCf 1111 --> ' + campaignAllocation.PercentageCf)
          }
        }

        index++;
      }
     console.table(this.camopaignHelicopterMonth.campaignHelicopterAllocationList)
      const allocateObj = this.camopaignHelicopterMonth.campaignHelicopterAllocationList[foundIndex];
     
      this.camopaignHelicopterMonth.campaignHelicopterMonthCostList.forEach(function (data) {
        if (data["AllocateFlag"]) {
          let Percentage = 0;
       
            Percentage = self.abStage ? allocateObj.PercentageAb : allocateObj.PercentageCf;
         

          data["ValueCf"] = (Percentage * data["CostValue"]) / 100;
          data["ValueCf"] = Math.round((data["ValueCf"] + Number.EPSILON) * 100) / 100;
          self.formZone.get(data["fromValueCf"]).setValue(data["ValueCf"]);
        //  console.log(data["CostName"])
       //   console.log(data["ValueCf"])
         }

      });
    }

    this.camopaignHelicopterMonth.campaignHelicopterMonthFlyingList.forEach(function (obj) {
 
      var Formula = obj.Formula;
      if (obj.VariableTypeCode == "C") {

        var PaxMile = self.tcBlock.PaxMile;
      //  var AvgPaxPerFlight = self.tcBlock.AvgPaxPerFlight;
        var AvgHourPerFlight = self.tcBlock.AvgHourPerFlight;

        if (obj.TimeFlag && obj.Formula == "[Average flying hour/flight]") {
          obj.TimeValueCf = AvgHourPerFlight;
          obj.Value = self.calculateValueByTime(obj.TimeValueCf);

   
        }
        else {
          if (Formula == "[Pax mile rate]") {
            obj.Value = PaxMile;
          }
          else if (Formula == "[Average PAX/flight]") {

            obj.Value = self.formZone.get(obj["formflyingMonth"]).value;
          }
        }
      } else if (obj.VariableTypeCode == "D") {

        Formula = Formula.replace("[SUM_PAX_PER_MONTH]", sumPaxPerMonth.toString());
        var helicopterMonthFlyings = self.camopaignHelicopterMonth.campaignHelicopterMonthFlyingList.filter(x => x.ParentFlyingInfoCode == obj.FlyingInfoCode);

        helicopterMonthFlyings.forEach(function (cObj) {

          var Description = "(" + cObj.Description + ")";
          Formula = Formula.replace(Description, cObj.Value.toString());
        })
   
        var ceilingModValue = 0;
        var ceilingFlag = calObj.isCeiling(Formula);


        var roundIndex = 0;
        var roundFlag = calObj.isRound(Formula);
        if (ceilingFlag) {
         
          ceilingModValue = calObj.getCeilingModValue(Formula);
       
          Formula = calObj.getCeilingFormula(Formula);
         
        }


        if (roundFlag) {

          roundIndex = calObj.getRoundIndex(Formula);
          Formula = calObj.getRoundFormula(Formula);
        }


       // obj.Value = eval(Formula);
        //obj.Value = new Function('return ' + Formula)();
       // const math = require('mathjs');
     
        obj.Value =evaluate(Formula); 
        if (ceilingFlag) {
          obj.Value = calObj.Ceiling(obj.Value, ceilingModValue);
      
        }

        if (roundFlag) {
          console.log(roundIndex)
          obj.Value = calObj.Round(obj.Value, roundIndex);
        
        }

        self.formZone.get(obj["formflyingMonth"]).setValue(obj.Value);

        if (obj.TimeFlag) {

          obj.TimeValueCf = self.convertTimeString(obj.Value);
        }

      }
   

    });


    
    this.camopaignHelicopterMonth.campaignHelicopterMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);

    });

    var estimateFlightHour = this.camopaignHelicopterMonth.campaignHelicopterMonthFlyingList.filter(z => z.Description == "Estimate Flying hour")[0].Value;
   
    calObj.sumPaxPerMonth = sumPaxPerMonth;
    calObj.estimateFlightHour = estimateFlightHour;
    calObj.calculateCostList(this.camopaignHelicopterMonth.campaignHelicopterMonthCostList, this.camopaignHelicopterMonth.costParentDtoList);
    this.camopaignHelicopterMonth.campaignHelicopterMonthCostList.forEach(function (data) {

      self.formZone.get(data["fromValueCf"]).setValue(data["ValueCf"]);
    });
 }


  formatDateTime(UpdateDttm) {
    var dt = new Date(UpdateDttm);
    var offset = dt.getTimezoneOffset();
 //   console.log(offset)

   
    return CommonUtilService.formatDateTime(UpdateDttm)
  }


  calculateValueByTime(TimeValue: string) {
    var result = 0;
    const tSplit = TimeValue.split(":");
    const hMin = parseFloat(tSplit[0]) * 60;
    result = hMin + parseFloat(tSplit[1]);
    return result;
  }


  convertTimeString(time) {
    var result = "";
    const hour = Math.floor(time / 60);
    const min = Math.ceil(time % 60);
    result = hour.toString() + ":" + min.toString();
    return result;
  }

  save() {

    const self = this;

    //console.log(this.camopaignHelicopterMonth)


    this.camopaignHelicopterMonth.AbFlag = this.abStage;

    this.camopaignHelicopterMonth.campaignHelicopterMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);
       
    });
    

    this.camopaignHelicopterMonth.campaignHelicopterMonthPaxPerMonthList.forEach(function (data) {

      data["paxPerMonthCf"] = parseFloat(self.formZone.get(data["formPaxPerMonth"]).value);
    
    });


    this.camopaignHelicopterMonth.campaignHelicopterMonthFlyingList.forEach(function (data) {

      data["ValueCf"] = parseFloat(self.formZone.get(data["formflyingMonth"]).value);

    });


 

    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateCampaignHelicopterMonth(this.camopaignHelicopterMonth).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });

  }

}
