import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-vessel-management-modal',
  templateUrl: './vessel-management-modal.component.html',
  styleUrls: ['./vessel-management-modal.component.css']
})
export class VesselManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  dtOptions: any = {};
  paramId: any;
  pageMode: any;
  costList = [];
  contractList = [];
  masterObj: any;
  isReadonly: boolean = false;
  costTypeCodeList: any[] = [];
  vesselTypeList: any;
  costCountingLink = 0;
  contractCountingLink = 0;
  updateDate: any;
  updateBy: any;
  addCostFlag: any = true;
  maxStartDate: any[] = [];
  minEndDate: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,
    private cdr: ChangeDetectorRef  ) { }

  projectList: any[] = [];
  activityList: any[] = [];
  costDtoList: any[] = [];
  costDefaluteList: any[];
  costNameList: any[] = [];
  defaluteFlagList: any[] = [];
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
    if (this.masterObj && this.masterObj != null) {
        this.vesselTypeList = this.masterObj.tcVesselType;
        this.projectList = this.masterObj.tcProject;
        this.activityList = this.masterObj.tcActivity;
        this.costDtoList = this.masterObj.costListDto;
 
        this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && (item.CostTypeCode == "MGO" || item.CostTypeCode == 'VS') && item.DefaluteFlag == true);
    }

    if (this.masterObj &&
      this.masterObj != null &&
      this.masterObj.resolverData &&
      this.masterObj.resolverData != null
    ) {
            this.formZone = this.formBuilder.group({
              'VesselName': new FormControl({ value: this.masterObj.resolverData.VesselName, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'VesselTypeCode': new FormControl({ value: this.masterObj.resolverData.VesselTypeCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'ActiveFlag': new FormControl({ value: this.masterObj.resolverData.ActiveFlag == true ? true : this.masterObj.resolverData.ActiveFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'Tons': new FormControl({ value: this.masterObj.resolverData.Tons, disabled: this.isReadonly }),
              'ProjectCode': new FormControl({ value: this.masterObj.resolverData.ProjectCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'ActivityCode': new FormControl({ value: this.masterObj.resolverData.ActivityCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'VesselCostList': this.formBuilder.array([]),
              'VesselContractList': this.formBuilder.array([]),
              'CreateDate': new FormControl({ value: "", disabled: true }),
              'CreateName': new FormControl({ value: "", disabled: true }),
              'UpdateDate': new FormControl({ value: "", disabled: true }),
              'UpdateName': new FormControl({ value: "", disabled: true }),
            });
    } else {
            this.formZone = this.formBuilder.group({
              'VesselName': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'VesselTypeCode': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'ActiveFlag': new FormControl({ value: false, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'Tons': new FormControl({ value: null, disabled: this.isReadonly }),
              'ProjectCode': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'ActivityCode': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
              'VesselCostList': this.formBuilder.array([]),
              'VesselContractList': this.formBuilder.array([]),
              'CreateDate': new FormControl({ value: "", disabled: true }),
              'CreateName': new FormControl({ value: "", disabled: true }),
              'UpdateDate': new FormControl({ value: "", disabled: true }),
              'UpdateName': new FormControl({ value: "", disabled: true }),
            });
    }

 

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.loadCostDefaluteFlag(this.costDefaluteList);
      this.formZone.get("ActiveFlag").setValue(true);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.VesselCostList &&
      this.masterObj?.resolverData.VesselCostList?.length > 0) {
      this.loadCost(this.masterObj?.resolverData.VesselCostList);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.VesselContractList &&
      this.masterObj?.resolverData.VesselContractList?.length > 0) {
      this.loadContract(this.masterObj?.resolverData.VesselContractList);
    }
  }

  loadCost(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        //this.costTypeCodeList.push(this.masterObj.tcCostType);
        this.filterLoadCostTypeCode(costList, i);
        this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag));
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
        }));
      }
      this.checkAddCost();
    }
  }

  loadCostDefaluteFlag(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        //this.costTypeCodeList.push(this.masterObj.tcCostType);
        this.filterLoadCostTypeCode(costList, i)
        this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag));
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "Value": new FormControl({ value: costListObj.Value, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
        }));
      }
      this.checkAddCost();
    }
  }

  filterLoadCostTypeCode(costList, i) {
    var costListObj = costList[i];
    var arrayMgoCostList = costList.filter(item => item.CostTypeCode == "MGO");
    var mgoCostList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "MGO");
    var arrayVsCostList = costList.filter(item => item.CostTypeCode == "VS");
    var vsCostList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "VS");

    if ((arrayMgoCostList >= mgoCostList) && !(arrayVsCostList >= vsCostList)) {
      if (costListObj.CostTypeCode == "MGO") {
        this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "MGO" || "VS");
      } else {
        this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "VS");
      }
    } else if ((arrayVsCostList >= vsCostList) && !(arrayMgoCostList >= mgoCostList)) {
      if (costListObj.CostTypeCode == "VS") {
        this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "VS" || "MGO");
      } else {
        this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "MGO");
      }
    } else if ((arrayVsCostList >= vsCostList) && (arrayMgoCostList >= mgoCostList)) {

      this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == costListObj.CostTypeCode);

    } else if (!(arrayVsCostList >= vsCostList) && !(arrayMgoCostList >= mgoCostList)) {

      this.costTypeCodeList[i] = this.masterObj.tcCostType;

    }
  }

  filterAddCostTypeCode() {
    var costList = this.formZone.getRawValue().VesselCostList;
    var arrayMgoCostList = costList.filter(item => item.CostTypeCode == "MGO");
    var mgoCostList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "MGO");
    var arrayVsCostList = costList.filter(item => item.CostTypeCode == "VS");
    var vsCostList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "VS");

    if ((arrayMgoCostList >= mgoCostList) && !(arrayVsCostList >= vsCostList)) {
     
      this.costTypeCodeList.push(this.masterObj.tcCostType.filter(item => item.Code == "VS"));

    } else if ((arrayVsCostList >= vsCostList) && !(arrayMgoCostList >= mgoCostList)) {

      this.costTypeCodeList.push(this.masterObj.tcCostType.filter(item => item.Code == "MGO"));

    } else if (!(arrayVsCostList >= vsCostList) && !(arrayMgoCostList >= mgoCostList)){

      this.costTypeCodeList.push(this.masterObj.tcCostType);

    }
  }

  filterCostTypeCode() {
    var costList = this.formZone.getRawValue().VesselCostList;
    if (costList && costList.length > 0) {
      var arrayMgoCostList = costList.filter(item => item.CostTypeCode == "MGO");
      var mgoCostList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "MGO");
      var arrayVsCostList = costList.filter(item => item.CostTypeCode == "VS");
      var vsCostList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.CostTypeCode == "VS");
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        if ((arrayMgoCostList >= mgoCostList) && !(arrayVsCostList >= vsCostList)) {
          if (costListObj.CostTypeCode == "MGO") {
            this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "MGO" || "VS");
          } else {
            this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "VS");
          }
        } else if ((arrayVsCostList >= vsCostList) && !(arrayMgoCostList >= mgoCostList)) {
          if (costListObj.CostTypeCode == "VS") {
            this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "VS" || "MGO");
          } else {
            this.costTypeCodeList[i] = this.masterObj.tcCostType.filter(item => item.Code == "MGO");
          }
        } else if (!(arrayVsCostList >= vsCostList) && !(arrayMgoCostList >= mgoCostList)) {
          this.costTypeCodeList[i] = this.masterObj.tcCostType;
        }
      }
    }
  }

  addCost() {
    this.costItems.push(this.formBuilder.group({
      "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
    }));
    this.filterAddCostTypeCode();
    this.checkAddCost();
  }


  deleteCost(index) {
    this.costItems.removeAt(index);
    this.costTypeCodeList.splice(index, 1);
    this.costNameList.splice(index, 1);
    this.defaluteFlagList.splice(index, 1);
    this.filterCostTypeCode();
    this.addCostFlag = true;
  }

  checkAddCost() {
    if (this.costItems.length >= this.costDtoList.filter(x => x.VariableTypeCode == 'C' && (x.CostTypeCode == "MGO" || x.CostTypeCode == 'VS')).length) {
      this.addCostFlag = false;
    }
  }

  costTypeCodeChangeValue(idx) {
    if (this.costItems && this.costItems.length > 0) {
      var item = this.costItems.at(idx);
      item.get("CostId").setValue(null);
      this.filterCostTypeCode();
      this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false);
    }
  }

  loadContract(contractList) {
    if (contractList && contractList.length > 0) {
      for (var i = 0; i < contractList.length; i++) {
        var contractListObj = contractList[i];
        this.maxStartDate.push(contractListObj.EndDate);
        this.minEndDate.push(contractListObj.StartDate);
        this.contractItems.push(this.formBuilder.group({
          "ContractNo": new FormControl({ value: contractListObj.ContractNo, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: contractListObj.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "StartDate": new FormControl({ value: contractListObj.StartDate, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "EndDate": new FormControl({ value: contractListObj.EndDate, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "UpdateDate": new FormControl({ value: CommonUtilService.formatDateTime(contractListObj.UpdateDttm), disabled: this.isReadonly }),
          "UpdateBy": new FormControl({ value: contractListObj.UpdateName, disabled: this.isReadonly })
        }));
      }
    }
  }

  addContract() {
    this.contractItems.push(this.formBuilder.group({
      "ContractNo": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
      "StartDate": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "EndDate": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "UpdateDate": new FormControl({ value: "", disabled: true }),
      "UpdateBy": new FormControl({ value: "", disabled: true }),
    }));
    this.maxStartDate.push(null);
    this.minEndDate.push(null);
  }

  deleteContract(index) {
    this.contractItems.removeAt(index);
    this.maxStartDate[index] = null;
    this.minEndDate[index] = null;
  }

  startDateChangeValue(index) {
    var contractItem = this.contractItems.at(index);
    this.minEndDate[index] = contractItem.get("StartDate").value;
    
  }

  endDateChangeValue(index) {
    var contractItem = this.contractItems.at(index);
    this.maxStartDate[index] = contractItem.get("EndDate").value;
  }

  cancelCreate() {
    this.router.navigate(['/vessel-management']);
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }


  get costItems(): FormArray {
    return this.formZone.get('VesselCostList') as FormArray;
  }

  get contractItems(): FormArray {
    return this.formZone.get('VesselContractList') as FormArray;
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
 

    if (!this.formZone.valid) {
     /* var errors = ErrorMessageService.getFormErrors(this.formZone);
      if (message) {
        this.alertDialog.open(AlertModalComponent, {
          disableClose: true,
          data: {
            message: message,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
      }*/
      return;
    }


    if (this.paramId && this.paramId != "0") {
      requestObj["IdEncoded"] = this.paramId;
    }

    if (this.costItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the cost is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    if (this.contractItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the contract is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    this.MasterData.checkVesselCost(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveVessel(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/vessel-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )
  }
}

