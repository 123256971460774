import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CampaignService {
  httpClient: any;
  constructor(private _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl) {
  }
   

  addCampaign(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/addCampaign', obj);
  }

  getCampaignObject(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/getCampaignObject?id=' + obj);
  }

  getCampaignTableByMode(mode, selectedYear, abStage) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/getCampaignTableByMode?mode=' + mode + "&selectedYear=" + selectedYear + "&abStage=" + abStage);
  }

  viewCampaignMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/viewCampaignMonth', obj);
  }

  viewBlockMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/viewBlockMonth', obj);
  }

  viewRigMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewRigMonth?Id=' + obj);
  }


  updateCampaignRigMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaignRigMonth', obj);
  }


  updateRigActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateRigActual', obj);
  }

  viewHelicopterMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewHelicopterMonth?Id=' + obj);
  }

  updateCampaignHelicopterMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaignHelicopterMonth', obj);
  }


  viewVesselMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewVesselMonth?Id=' + obj);
  }

  updateCampaignVesselMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaignVesselMonth', obj);
  }

  updateVesselActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateVesselActual', obj);
  }


  viewSupplyBaseMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewSupplyBaseMonth?Id=' + obj);
  }

  updateCampaignSupplyBaseMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaignSupplyBaseMonth', obj);
  }

  updateSupplyBaseActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateSupplyBaseActual', obj);
  }

  viewExtraBudgetMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewExtraBudgetMonth?Id=' + obj);
  }

  updateCampaignExtraBudgetMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaignExtraBudgetMonth', obj);
  }

  viewMarineSupportMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewMarineSupportMonth?Id=' + obj);
  }

  updateCampaignMarineSupportMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaignMarineSupportMonth', obj);
  }

  updateMarineSupportActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateMarineSupportActual', obj);
  }

  viewBlockTakittaMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewBlockTakittaMonth?Id=' + obj);
  }

  updateBlockTakittaMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockTakittaMonth', obj);
  }

  updateBlockTakittaActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockTakittaActual', obj);
  }

  viewBlockMgtcMonth(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewBlockMgtcMonth?Id=' + obj);
  }

  updateBlockMgtcMonth(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockMgtcMonth', obj);
  }

  updateBlockMgtcActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockMgtcActual', obj);
  }

  updateBlockHelicopterActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockHelicopterActual', obj);
  }

  updateBlockExtraBudgetActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockExtraBudgetActual', obj);
  }

  updatePeriodCampaign(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updatePeriodCampaign', obj);
  }

  viewCampaign(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/viewCampaign?Id=' + obj);
  }

  updateCampaign(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateCampaign', obj);
  }

  deleteCampaign(obj) {
    return this._httpClient.get<any>(this.baseUrl + 'api/Campaign/deleteCampaign?Id=' + obj);
  }


  updateBlockRigActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockRigActual', obj);
  }

  updateBlockVesselActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockVesselActual', obj);
  }

  updateBlockPsbRsbActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockPsbRsbActual', obj);
  }

  updateBlockMarineSupportActual(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateBlockMarineSupportActual', obj);
  }


  updateReductionPercentage(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateReductionPercentage', obj);
  }

  updateMgoUnitCost(obj) {
    return this._httpClient.post<any>(this.baseUrl + 'api/Campaign/updateMgoUnitCost', obj);
  }
}
