import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MasterDataService } from '../../../_services/MasterData.service';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-helicopter-management-modal',
  templateUrl: './helicopter-management-modal.component.html',
  styleUrls: ['./helicopter-management-modal.component.css']
})
export class HelicopterManagementModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  dtOptions: any = {};
  paramId: any;
  pageMode: any;
  masterObj: any;
  addCostFlag: any = true;
  isReadonly: boolean = false;
  addPaxMonthFlag: any = true;
  tapPaxMonthFlag: any = true;
  defaluteFlagList: any[] = [];
  costList = [];
  paxMonthList = [];
  paxmonthCountingLink = 0;
  updateDate: String;
  updateBy: any;
  supportMode: any;
  costDefaluteList: any[];
  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  costTypeCodeList: any[];
  projectList: any[] = [];
  blockList: any[] = [];
  departmentList: any[] = [];
  activityList: any[] = [];
  costDtoList: any[] = [];
  costNameList: any[] = [];
  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
    }];
  supportTypeList: any[] = [];
 

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );
    if (this.masterObj && this.masterObj != null) {
        this.projectList = this.masterObj.tcProject;
        this.blockList = this.masterObj.tcBlock;
        this.supportTypeList = this.masterObj.tcSupportType
        this.activityList = this.masterObj.tcActivity;
        this.departmentList = this.masterObj.tcDepartment;
        this.costDtoList = this.masterObj.costListDto;
        this.costTypeCodeList = this.masterObj.tcCostType;
        this.costDefaluteList = this.masterObj.costListDto.filter(item => item.VariableTypeCode == 'C' && item.BudgetTypeCode == 'HC' && item.CostTypeCode == "HC" && item.DefaluteFlag == true);
    }
    if (this.masterObj &&
      this.masterObj != null &&
      this.masterObj.resolverData &&
      this.masterObj.resolverData != null
    ) {
          this.formZone = this.formBuilder.group({
            'HelicopterName': new FormControl({ value: this.masterObj.resolverData.HelicopterName, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            'ActiveFlag': new FormControl({ value: this.masterObj.resolverData.ActiveFlag == true ? true : this.masterObj.resolverData.ActiveFlag == false ? false : null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            'BlockCode': new FormControl({ value: this.masterObj.resolverData.BlockCode, disabled: this.isReadonly }),
            'ProjectCode': new FormControl({ value: this.masterObj.resolverData.ProjectCode, disabled: this.isReadonly }),
            'ActivityCode': new FormControl({ value: this.masterObj.resolverData.ActivityCode, disabled: this.isReadonly }),
            'SupportTypeCode': new FormControl({ value: this.masterObj.resolverData.SupportTypeCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            'HelicopterCostList': this.formBuilder.array([]),
            'HelicopterPaxPerMonthList': this.formBuilder.array([]),
            'CreateDate': new FormControl({ value: "", disabled: true }),
            'CreateName': new FormControl({ value: "", disabled: true }),
            'UpdateDate': new FormControl({ value: "", disabled: true }),
            'UpdateName': new FormControl({ value: "", disabled: true }),
          });

    } else {
          this.formZone = this.formBuilder.group({
            'HelicopterName': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            'ActiveFlag': new FormControl({ value:  null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            'BlockCode': new FormControl({ value: null, disabled: this.isReadonly }),
            'ProjectCode': new FormControl({ value: null, disabled: this.isReadonly }),
            'ActivityCode': new FormControl({ value: null, disabled: this.isReadonly }),
            'SupportTypeCode': new FormControl({ value: null, disabled: this.isReadonly }, Validators.compose([Validators.required])),
            'HelicopterCostList': this.formBuilder.array([]),
            'HelicopterPaxPerMonthList': this.formBuilder.array([]),
            'CreateDate': new FormControl({ value: "", disabled: true }),
            'CreateName': new FormControl({ value: "", disabled: true }),
            'UpdateDate': new FormControl({ value: "", disabled: true }),
            'UpdateName': new FormControl({ value: "", disabled: true }),
          });
    }

   

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    if (this.pageMode == "add") {
      this.addPaxMonthFlag = false;
      this.loadCostDefaluteFlag(this.costDefaluteList);
      this.formZone.get("ActiveFlag").setValue(true);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.HelicopterCostList &&
      this.masterObj?.resolverData.HelicopterCostList?.length > 0) {
      this.loadCost(this.masterObj?.resolverData.HelicopterCostList);
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.HelicopterPaxPerMonthList &&
      this.masterObj?.resolverData.HelicopterPaxPerMonthList?.length > 0) {
      this.loadPaxPerMonth(this.masterObj?.resolverData.HelicopterPaxPerMonthList);
    }
    
  }

  loadCost(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        let checkAllowcationFlag = this.costDtoList.find(x => x.Id == costListObj.CostId && x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag).AllocateFlag;
        console.log("checkAllowcationFlag");
        console.log(checkAllowcationFlag);
        this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag));
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "CostId": new FormControl({ value: costListObj.CostId, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "Value": new FormControl({ value: costListObj.Value, disabled: (checkAllowcationFlag || this.isReadonly) }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: costListObj.ActiveFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
        }));
      }
      this.checkAddCost();
    }
  }

  loadCostDefaluteFlag(costList) {
    if (costList && costList.length > 0) {
      for (var i = 0; i < costList.length; i++) {
        var costListObj = costList[i];
        this.costNameList.push(this.costDtoList.filter(x => x.CostTypeCode == costListObj.CostTypeCode && x.VariableTypeCode == 'C' && x.DefaluteFlag == costListObj.DefaluteFlag));
        this.defaluteFlagList.push(costListObj.DefaluteFlag);
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: costListObj.CostTypeCode, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "CostId": new FormControl({ value: costListObj.Id, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required])),
          "Value": new FormControl({ value: costListObj.Value, disabled: (costListObj.AllocateFlag || this.isReadonly) }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: costListObj.DefaluteFlag, disabled: (costListObj.DefaluteFlag) || this.isReadonly }, Validators.compose([Validators.required]))
        }));
      }
      this.checkAddCost();
    }
  }

  addCost() {
    this.costItems.push(this.formBuilder.group({
      "CostTypeCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "CostId": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "Value": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required]))
    }));
    this.checkAddCost();
  }

  deleteCost(index) {
    this.costItems.removeAt(index);
    this.costNameList.splice(index, 1);
    this.defaluteFlagList.splice(index, 1);
    this.addCostFlag = true;
  }

  checkAddCost() {
    if (this.costItems.length >= this.costDtoList.filter(x => x.VariableTypeCode == 'C' && x.BudgetTypeCode == 'HC' && x.CostTypeCode == "HC").length) {
      this.addCostFlag = false;
    }
  }

  costTypeCodeChangeValue(idx) {
    if (this.costItems && this.costItems.length > 0) {
      var item = this.costItems.at(idx);
      item.get("CostId").setValue(null);
      this.costNameList[idx] = this.costDtoList.filter(x => x.CostTypeCode == item.get("CostTypeCode").value && x.VariableTypeCode == 'C' && x.DefaluteFlag == false);
    }
  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  checkAddPaxMonth() {
    if (this.formZone.get("SupportTypeCode").value != 'A') {
      if ((this.paxPerMonthItems.length >= this.departmentList.filter(item => item.SupportTypeCode == this.formZone.get("SupportTypeCode").value).length) || this.formZone.get('SupportTypeCode').invalid) {
        this.addPaxMonthFlag = false;
      }
    } else {
      if ((this.paxPerMonthItems.length >= this.departmentList.length) || this.formZone.get('SupportTypeCode').invalid) {
        this.addPaxMonthFlag = false;
      }
    }
  }

  supportChangeValue() {
    if (this.formZone.get("SupportTypeCode").value != 'A') {
      this.departmentList = this.masterObj.tcDepartment.filter(item => item.SupportTypeCode == this.formZone.get("SupportTypeCode").value);
    } else {
      this.departmentList = this.masterObj.tcDepartment;
    }

    this.clearFormArray(this.paxPerMonthItems);
    this.addPaxMonthFlag = true;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  loadPaxPerMonth(paxPerMonthList) {
    if (paxPerMonthList && paxPerMonthList.length > 0) {
      for (var i = 0; i < paxPerMonthList.length; i++) {
        var paxPerMonthListObj = paxPerMonthList[i];
        if (this.formZone.get("SupportTypeCode").value != 'A') {
          this.departmentList = this.masterObj.tcDepartment.filter(item => item.SupportTypeCode == this.formZone.get("SupportTypeCode").value);
        } else {
          this.departmentList = this.masterObj.tcDepartment;
        }
        this.paxPerMonthItems.push(this.formBuilder.group({
          "DepartmentCode": new FormControl({ value: paxPerMonthListObj.DepartmentCode, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "PaxPerMonth": new FormControl({ value: paxPerMonthListObj.PaxPerMonth, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "ActiveFlag": new FormControl({ value: paxPerMonthListObj.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
          "UpdateDate": new FormControl({ value: CommonUtilService.formatDateTime(paxPerMonthListObj.UpdateDttm), disabled: this.isReadonly }),
          "UpdateBy": new FormControl({ value: paxPerMonthListObj.UpdateName, disabled: this.isReadonly })
        }));
      }
      this.checkAddPaxMonth();
    }
  }

  addpaxmonth() {
    this.paxPerMonthItems.push(this.formBuilder.group({
      "DepartmentCode": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "PaxPerMonth": new FormControl({ value: "", disabled: false }, Validators.compose([Validators.required])),
      "ActiveFlag": new FormControl({ value: true, disabled: false }, Validators.compose([Validators.required])),
      "UpdateDate": new FormControl({ value: "", disabled: true }),
      "UpdateBy": new FormControl({ value: "", disabled: true }),
    }));
    this.checkAddPaxMonth()
  }

  deletePaxmonth(index) {
    this.paxPerMonthItems.removeAt(index);
    this.addPaxMonthFlag = true;
  }

  cancelCreate() {
    this.router.navigate(['/helicopter-management']);
  }

  get costItems(): FormArray {
    return this.formZone.get('HelicopterCostList') as FormArray;
  }

  get paxPerMonthItems(): FormArray {
    return this.formZone.get('HelicopterPaxPerMonthList') as FormArray;
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
  

    if (!this.formZone.valid ) {
     
      return;
    }


    if (this.paramId && this.paramId != "0") {
      requestObj["IdEncoded"] = this.paramId;
    }

    if (this.costItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the cost is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    if (this.paxPerMonthItems.length == 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'The data cannot be save. Because the PAX/Month is null',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    this.MasterData.checkHelicopterCost(requestObj).subscribe(
      result => {
        if (result.status == "invalid") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveHelicopter(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/helicopter-management']);
                    });
                },
                error => console.error(error)
              )
            }
          });

        }
      },
      error => console.error(error)
    )
  }
}


