import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { CampaignService } from '../../_services/Campaign.service';
import { CommonUtilService } from '../../_helpers/CommonUtilService';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-block-cost',
  templateUrl: './block-cost.component.html',
  styleUrls: ['./block-cost.component.css']
})
export class BlockCostComponent implements OnInit {
  @Input() year: string;
  @Input() monthCode: string;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  
  showBlockFlag: boolean=false;
  paramYear: string;
  paramMonthCode: string;
  monthName: string;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() canUpdateActualCost: boolean;
  @Input() canUpdateCost: boolean;
  @Input() canEditABFlag: boolean;
  

  blockMgtcMonthList = [];
  blockHelicopterMonthList = [];
  blockTakittaMonthList = [];
  blockExtraBudgetMonthList = [];
  blockRigMonthList = [];
  blockVesselMonthList = []
  blockPsbRsbMonthList = []
  blockMarineSupportMonthList = []
  isShowRig = false;
  isShowHelicopter = false;
  isShowVessle = false;
  canUpdateCostSupplyBase = false;
  canUpdateCostMgtc = false;
  isShowMarineSupport = false;
  isShowOperationSupport = false;


  constructor(private campaignService: CampaignService,
 private authenticationService: AuthenticationService  ) { }

  ngOnInit(): void {
    const user = this.authenticationService.userValue;
  /*  var rigShow = CommonUtilService.getPageAuthorized("RI", user);
    var helicopterShow = CommonUtilService.getPageAuthorized("HE", user);
    var vessleShow = CommonUtilService.getPageAuthorized("VE", user);*/
    var supplyBaseShow = CommonUtilService.getPageAuthorized("SU", user);
    var mgtcShow = CommonUtilService.getPageAuthorized("MG", user);
  /*  var marineSupportShow = CommonUtilService.getPageAuthorized("MA", user);
    var operationSupportShow = CommonUtilService.getPageAuthorized("OP", user);*/
  
    if (supplyBaseShow == 'M' && this.canUpdateCost) {
      this.canUpdateCostSupplyBase = true;
    }
    if (mgtcShow == 'M' && this.canUpdateCost) {
      this.canUpdateCostMgtc = true;
    }
   
  }


  ngOnChanges(changes: SimpleChanges) {
    // alert(this.paramCampaignId)
    //alert(this.paramMonthCode)
    this.paramYear = this.year;
    this.paramMonthCode = this.monthCode;
    this.monthName= CommonUtilService.getMonthShortNameByCode(this.monthCode);
   
    this.viewBlockMonth();
     
  }
  viewBlockMonth() {
    var request = {
      "year": this.paramYear,
      "month": this.paramMonthCode
    };

    this.campaignService.viewBlockMonth(request).subscribe(
      result => {

        // console.log(result)

        this.blockMgtcMonthList = result.resolverData.blockMgtcMonthList;
        this.blockHelicopterMonthList = result.resolverData.blockHelicopterMonthList;
        this.blockTakittaMonthList = result.resolverData.blockTakittaMonthList;
        this.blockExtraBudgetMonthList = result.resolverData.blockExtraBudgetMonthList;
        this.blockRigMonthList = result.resolverData.blockRigMonthList;
        this.blockVesselMonthList = result.resolverData.blockVesselMonthList;
        this.blockPsbRsbMonthList = result.resolverData.blockPsbRsbMonthList;
        this.blockMarineSupportMonthList = result.resolverData.blockMarineSupportMonthList;

        if (this.blockMgtcMonthList ||
          this.blockHelicopterMonthList ||
          this.blockTakittaMonthList ||
          this.blockExtraBudgetMonthList ||
          this.blockRigMonthList ||
          this.blockVesselMonthList ||
          this.blockPsbRsbMonthList ||
          this.blockMarineSupportMonthList 
          ) {
          this.showBlockFlag = true;
        } else {
          this.showBlockFlag = false;
        }

      },
      error => console.error(error));
  }
  //parameter

  tabChange($event) {
    console.log($event)
  }

  viewBlockMonthByUpdateCost(msg) {
    console.log(msg);
    this.viewBlockMonth();
  }

}
