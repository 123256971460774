import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignHelicopterModalComponent } from './campaign-helicopter-modal/campaign-helicopter-modal.component';
import { CampaignService } from '../../_services/Campaign.service';
@Component({
  selector: 'app-campaign-helicopter',
  templateUrl: './campaign-helicopter.component.html',
  styleUrls: ['./campaign-helicopter.component.css']
})
export class CampaignHelicopterComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
 
  @Input() campaignHelicopterMonthList: [];
  @Input() CampaignName: string;
  @Input() BlockCode: string;
  @Input() freeze: boolean;
  @Input() abStage: boolean;
  @Input() canFreeze: boolean;
  @Input() isReadonly: boolean;
  @Input() canUpdateCost: boolean;
  @Input() canEditABFlag: boolean;
  constructor(public _matDialog: MatDialog,
    private confirmDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
    // alert(this.monthCode)
    //alert(this.parameter)
   // alert("CampaignName  > "+this.CampaignName)
   // alert(this.BlockCode)
  }

  summaryTariffReduction() {
  const self = this;
  var result = 0;
  this.campaignHelicopterMonthList.forEach(function (data) {
    if (self.abStage) {
      result += data["ReductionPercentageTariffAb"];
    } else {
      result += data["ReductionPercentageTariffCf"];
    }
  })
  return result;
}

  summaryReduction() {
    const self = this;
    var result = 0;
    this.campaignHelicopterMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["ReductionPercentageAb"];
      } else {
        result += data["ReductionPercentageCf"];
      }
    })
    return result;
  }

  summaryTariff() {
    var result = 0;
    const self = this;
    this.campaignHelicopterMonthList.forEach(function (data) {
    
      if (self.abStage) {
        result += data["TariffAb"];
      } else {
        result += data["TariffCf"];
      }
    })
    return result;
  }

  summaryBudgetCf() {
    var result = 0;
    const self = this;
    this.campaignHelicopterMonthList.forEach(function (data) {
      if (self.abStage) {
        result += data["BudgetAb"];
      } else {
        result += data["BudgetCf"];
      }      
    })
    return result;
  }


  openUpdateCost(id) {
    

    this.campaignService.viewHelicopterMonth(id).subscribe(
      result => {

        var tcBlockList = result.tcBlock.filter(x => x.Code == this.BlockCode);
        var tcBlock = tcBlockList[0];
      
        const alertDialog = this._matDialog.open(CampaignHelicopterModalComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '1200Px',
          width: '1000px',
          data: {
            //proposal: proposal || {},
            //   editObj: data,
            //masterObj: self.masterObj,
            CampaignName: this.CampaignName,
            resolverData: result.resolverData,
            freeze: this.freeze,
            abStage: this.abStage, 
            tcBlock: tcBlock,
            canFreeze: this.canFreeze,
            isReadonly: this.isReadonly,
            canUpdateCost: this.canUpdateCost,
            canEditABFlag: this.canEditABFlag
          }
        });

        alertDialog.afterClosed()
          .subscribe(response => {
            if (response != 'Close') {
              //   self.isSearch();
              //   self.cdr.detectChanges();

              this.childEvent.emit('helicopter update');
            }
          });

      }, error => console.error(error));

  
  }



 
}
