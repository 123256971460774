import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { CalculateCostService } from '../../../_helpers/CalculateCostService';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CampaignService } from '../../../_services/Campaign.service';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';

@Component({
  selector: 'app-campaign-vessel-modal',
  templateUrl: './campaign-vessel-modal.component.html',
  styleUrls: ['./campaign-vessel-modal.component.css']
})
export class CampaignVesselModalComponent implements OnInit {
  @Input() formZone: FormGroup;
  freeze: boolean;
  abStage: boolean;
  campaignVesselMonth: any;
  countingList = 0;
  canFreeze: boolean;
  isReadonly: boolean;
  canUpdateCost: boolean;
  canEditABFlag: boolean;

  constructor(public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.campaignVesselMonth = this._data.resolverData
    this.freeze = this._data.freeze;
    this.abStage = this._data.abStage;
    this.canFreeze = this._data.canFreeze;
    this.isReadonly = this._data.isReadonly;
    this.canUpdateCost = this._data.canUpdateCost;
    this.canEditABFlag = this._data.canEditABFlag;
    this.formZone = new FormGroup({
      rigName: new FormControl({ value: this.campaignVesselMonth.VesselName, disabled: true }),
      campaignName: new FormControl({ value: this._data.CampaignName, disabled: true }),
      month: new FormControl({ value: CommonUtilService.getMonthShortNameByCode(this.campaignVesselMonth.MonthCode), disabled: true }),
      year: new FormControl({ value: this.campaignVesselMonth.Year, disabled: true }),
      dayCount: new FormControl({ value: this.abStage ? this.campaignVesselMonth.DayAb : this.campaignVesselMonth.DayCf, disabled: (this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage) }),
    });

    this.loadCost();
  }

  loadCost() {

    for (var i = 0; i < this.campaignVesselMonth.campaignVesselMonthCostList.length; i++) {
      var costObj = this.campaignVesselMonth.campaignVesselMonthCostList[i];
      this.formZone.addControl("fromValueCf_" + i, new FormControl({ value: this.abStage ? costObj.ValueAb : costObj.ValueCf, disabled: (costObj.VariableTypeCode == 'D' || (this.freeze && !this.canFreeze) || !this.canUpdateCost || (!this.canEditABFlag && this.abStage)) }, Validators.compose([Validators.required])));
      this.formZone.updateValueAndValidity();
      costObj["fromValueCf"] = "fromValueCf_" + i;

      this.countingList++;

    }
  }

  calculateCost() {
    const self = this;
    this.campaignVesselMonth.campaignVesselMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);

    });

    const calObj = new CalculateCostService();
    calObj.campaignMonth = this.campaignVesselMonth.campaignMonth;

    calObj._days = parseFloat(self.formZone.get("dayCount").value);
    calObj.calculateCostList(this.campaignVesselMonth.campaignVesselMonthCostList, this.campaignVesselMonth.costParentDtoList);
    this.campaignVesselMonth.campaignVesselMonthCostList.forEach(function (data) {

      self.formZone.get(data["fromValueCf"]).setValue(data["ValueCf"]);
    });
  }


  formatDateTime(UpdateDttm) {
    return CommonUtilService.formatDateTime(UpdateDttm)
  }

  save() {

    const self = this;
    this.campaignVesselMonth.DayCf = self.formZone.get("dayCount").value;

    this.campaignVesselMonth.campaignVesselMonthCostList.forEach(function (data) {
      data["ValueCf"] = parseFloat(self.formZone.get(data["fromValueCf"]).value);
      if (self.abStage) {
        data["ValueAb"] = data["ValueCf"];
      }
    });
    this.campaignVesselMonth.AbFlag = this.abStage;


    const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: 'Would you like to save?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.campaignService.updateCampaignVesselMonth(this.campaignVesselMonth).subscribe(
          result => {
            const alertDialog = this.alertDialog.open(AlertModalComponent, {
              disableClose: true,
              data: {
                message: 'Save Successfully.',
                buttonText: {
                  cancel: 'OK'
                }
              }
            });
            alertDialog.afterClosed()
              .subscribe(response => {
                this.matDialogRef.close('complete')
              });
          },
          error => console.error(error)
        )
      }
    });


  }

}
