import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertModalComponent } from '../../../common-modal/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../../../common-modal/confirm-modal/confirm-modal.component';
import { CostElementConfigurationModalDeatilComponent } from '../../../master-data/cost-element-configuration/cost-element-configuration-modal/cost-element-configuration-modal-detail/cost-element-configuration-modal-detail.component';
import { CommonUtilService } from '../../../_helpers/CommonUtilService';
import { ErrorMessageService } from '../../../_services/ErrorMessageService';
import { MasterDataService } from '../../../_services/MasterData.service';

@Component({
  selector: 'app-cost-element-configuration-modal',
  templateUrl: './cost-element-configuration-modal.component.html',
  styleUrls: ['./cost-element-configuration-modal.component.css']
})
export class CostElementConfigurationModalComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private alertDialog: MatDialog,
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private MasterData: MasterDataService,
    private router: Router,) { }

  @Input() formZone: FormGroup;
  dtOptions: any = {};
  isReadonly: boolean = false;
  paramId: any;
  pageMode: any;
  masterObj: any;
  paramCode: any;

  yearList: any[] = [];

  startYearList: any[] = [];

  endYearList: any[] = [];

  operationSupportList: any[] = [];

  summaryMethodList: any[] = [];

  activeList: any[] = [{
    Code: "Y",
    Description: "Active"
  }, {
    Code: "N",
    Description: "Inactive"
  }];

  stageList: any[] = [];

  blockList: any[] = [];

  firstCostFlag = false;

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.paramId = params['id'];
      this.pageMode = params['mode'];
    });

    this.isReadonly = this.pageMode == 'view';

    this.route.data.subscribe(data =>
      this.masterObj = data.masterObj
    );


    this.formZone = this.formBuilder.group({
      'OperationSupportCode': new FormControl({ value: this.masterObj?.resolverData?.OperationSupportCode, disabled: (this.masterObj?.resolverData?.Id) || this.isReadonly }, Validators.compose([Validators.required])),
      'SummaryMethodCode': new FormControl({ value: this.masterObj?.resolverData?.SummaryMethodCode, disabled: (this.masterObj?.resolverData?.Id) || this.isReadonly },Validators.compose([Validators.required])),
      'StartYear': new FormControl({ value: (this.masterObj?.resolverData?.StartYear == 0 ? null : this.masterObj?.resolverData?.StartYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'EndYear': new FormControl({ value: (this.masterObj?.resolverData?.EndYear == 0 ? null : this.masterObj?.resolverData?.EndYear), disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'StageCode': new FormControl({ value: this.masterObj?.resolverData?.StageCode, disabled: (this.masterObj?.resolverData?.Id) || this.isReadonly }, Validators.compose([Validators.required])),
      'ActiveFlag': new FormControl({ value: (this.masterObj?.resolverData?.Id == null || this.masterObj?.resolverData?.Id == "")  ? true : this.masterObj?.resolverData?.ActiveFlag, disabled: this.isReadonly }, Validators.compose([Validators.required])),
      'CostElementItemList': this.formBuilder.array([]),
      'CostElementFormulaDtoList': this.formBuilder.array([]),
      'CreateDate': new FormControl({ value: "", disabled: true }),
      'CreateName': new FormControl({ value: "", disabled: true }),
      'UpdateDate': new FormControl({ value: "", disabled: true }),
      'UpdateName': new FormControl({ value: "", disabled: true }),
    });

    if (this.masterObj?.resolverData && this.masterObj?.resolverData?.Id != 0) {
      this.formZone.get("CreateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.CreateDttm));
      this.formZone.get("CreateName").setValue(this.masterObj?.resolverData.CreateName);
      this.formZone.get("UpdateDate").setValue(CommonUtilService.formatDateTime(this.masterObj?.resolverData.UpdateDttm));
      this.formZone.get("UpdateName").setValue(this.masterObj?.resolverData.UpdateName);
    }

    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 11; i++) {
      if (i == 5) {
        this.yearList.push({ Code: currentYear });
      }
      else if (i < 5) {
        this.yearList.push({ Code: currentYear - (5 - i) });
      }
      else if (i > 5) {
        this.yearList.push({ Code: currentYear + (i - 5) });
      }
    }

    this.startYearList = this.yearList;

    this.endYearList = this.yearList;

    this.operationSupportList = this.masterObj.tcOperationSupport;
    this.summaryMethodList = this.masterObj.tcSummaryMethod;
    this.stageList = this.masterObj.tcStage;
    this.blockList = this.masterObj.tcBlock;

    //console.log("this.paramId : " + this.paramId);

    if (this.paramId != 0) {
      this.loadCostElemenet();
    } else {
      this.firstCostFlag = true;
      this.formulaItems.push(this.formBuilder.group({
        "BlockCode": new FormControl({ value: "ALL", disabled: true }),
        "BlockName": new FormControl({ value: "ALL", disabled: true }),
        "Formula": new FormControl({ value: "", disabled: true }),
      }));
    }
    

  }

  getErrorMessage(obj) {
    return ErrorMessageService.getErrorMessageByCode(obj);
  }

  cancelCreate() {
    this.router.navigate(['/cost-element-configuration']);
  }

  get costItems(): FormArray {
    return this.formZone.get('CostElementItemList') as FormArray;
  }

  get formulaItems(): FormArray {
    return this.formZone.get('CostElementFormulaDtoList') as FormArray;
  }

  addCost() {
    console.log("Add");
    console.log(this.formZone.get('OperationSupportCode').value);
    if (this.formZone.get('OperationSupportCode').value && this.formZone.get('SummaryMethodCode').value) {
      this.openCost();
    } else {
      const alertDialog = this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'Please Choose Operation Support and Summary Method.',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      alertDialog.afterClosed()
        .subscribe(response => {
          //this.router.navigate(['/cost-element-configuration']);
        });
    }
  }

  operationClearAll(idx) {
    let formulaAllocationCode = this.formulaItems.at(idx).get("BlockCode").value;
    this.formulaItems.removeAt(idx);
    let costItems = (this.costItems as FormArray).controls.filter(
      x => x.get("BlockCode").value != formulaAllocationCode
    );

    this.costItems.controls = costItems;

    let seq = 0;
    for (let i = 0; i < costItems.length; i++) {
      
      if (this.costItems.at(i).get("CostFlag").value) {
        seq += 1;
        this.costItems.at(i).patchValue({
          Seq: seq,
        });
      }

      this.costItems.at(i).patchValue({
        Priority: i,
      });
    }
    if (this.formulaItems.length <= 0) {
      this.formZone.get('OperationSupportCode').enable();
      this.formZone.get('OperationSupportCode').updateValueAndValidity();
      this.formZone.get('SummaryMethodCode').enable();
      this.formZone.get('SummaryMethodCode').updateValueAndValidity();
      this.firstCostFlag = true;
      this.formulaItems.push(this.formBuilder.group({
        "BlockCode": new FormControl({ value: "ALL", disabled: true }),
        "BlockName": new FormControl({ value: "ALL", disabled: true }),
        "Formula": new FormControl({ value: "", disabled: true }),
      }));
    }
  }

  allocationPercentageClick(idx) {
    this.formZone.get('OperationSupportCode').disable();
    this.formZone.get('OperationSupportCode').updateValueAndValidity();
    this.formZone.get('SummaryMethodCode').disable();
    this.formZone.get('SummaryMethodCode').updateValueAndValidity();

    if(!this.firstCostFlag) {
      let formulaAllocationCode = this.formulaItems.at(idx).get("BlockCode").value;
      let formulaAllocationName = this.formulaItems.at(idx).get("BlockName").value;

      let operationSupportCode = this.costItems.at(idx).get("CostTypeCode").value;
      let operationSupportName = this.costItems.at(idx).get("CostTypeName").value;

      let costName = this.costItems.at(idx).get("CostName").value;
      let costId = this.costItems.at(idx).get("CostId").value;

      let checkAddCost = false;

      //if (checkAddCost) {
      this.costItems.push(this.formBuilder.group({
        "Symbol": new FormControl({ value: "(Allocation percentage)", disabled: true }),
        "CostFlag": new FormControl({ value: false, disabled: true }),
        "Priority": new FormControl({ value: (this.costItems.length + 1), disabled: true }),
        "BlockCode": new FormControl({ value: (formulaAllocationCode == null || formulaAllocationCode == "") ? "ALL" : formulaAllocationCode, disabled: true }),
        "BlockName": new FormControl({ value: (formulaAllocationName == null || formulaAllocationName == "") ? "ALL" : formulaAllocationName, disabled: true }),
        "CostTypeCode": new FormControl({ value: operationSupportCode, disabled: true }),
        "CostTypeName": new FormControl({ value: operationSupportName, disabled: true }),
        "CostName": new FormControl({ value: costName, disabled: true }),
        "CostId": new FormControl({ value: 0, disabled: true }),
      }));

      let costItems = (this.costItems as FormArray).controls.filter(
        x => x.get("BlockCode").value == formulaAllocationCode
      );

      let formula = "";
      costItems.forEach(detail => {

        let data = "";
        if (detail.get("CostFlag").value) {
          data = "(" + detail.get("CostName").value.trim() + ")";
        } else {
          data = detail.get("Symbol").value;
        }

        formula += " " + data;
      });


      if (formulaAllocationCode == "ALL") {

        (this.formulaItems as FormArray).controls.find(
          x => x.get("BlockCode").value == formulaAllocationCode
        ).patchValue({
          Formula: formula,
        });

      } else {
        let itemAll = this.formulaItems.controls.find(
          x => x.get("BlockCode").value == "ALL"
        );

        (this.formulaItems as FormArray).controls.find(
          x => x.get("BlockCode").value == formulaAllocationCode
        ).patchValue({
          Formula: itemAll.get("Formula").value + formula,
        });
      }
    } else {
      this.costItems.push(this.formBuilder.group({
        "Symbol": new FormControl({ value: "(Allocation percentage)", disabled: true }),
        "CostFlag": new FormControl({ value: false, disabled: true }),
        "Priority": new FormControl({ value: (this.costItems.length + 1), disabled: true }),
        "BlockCode": new FormControl({ value: "ALL", disabled: true }),
        "BlockName": new FormControl({ value: "ALL", disabled: true }),
        "CostTypeCode": new FormControl({ value: "", disabled: true }),
        "CostTypeName": new FormControl({ value: "", disabled: true }),
        "CostName": new FormControl({ value: "", disabled: true }),
        "CostId": new FormControl({ value: 0, disabled: true }),
      }));

      let costItems = (this.costItems as FormArray).controls.filter(
        x => x.get("BlockCode").value == "ALL"
      );

      let formula = "";
      costItems.forEach(detail => {

        let data = "";
        if (detail.get("CostFlag").value) {
          data = "(" + detail.get("CostName").value.trim() + ")";
        } else {
          data = detail.get("Symbol").value;
        }

        formula += " " + data;
      });

      (this.formulaItems as FormArray).controls.find(
        x => x.get("BlockCode").value == "ALL"
      ).patchValue({
        Formula: formula,
      });

      this.firstCostFlag = false;
    }
  }

  operationClick(idx, symbol) {
    this.formZone.get('OperationSupportCode').disable();
    this.formZone.get('OperationSupportCode').updateValueAndValidity();
    this.formZone.get('SummaryMethodCode').disable();
    this.formZone.get('SummaryMethodCode').updateValueAndValidity();
    if (symbol != "undo") {
      if (!this.firstCostFlag) {
        let formulaAllocationCode = this.formulaItems.at(idx).get("BlockCode").value;
        let formulaAllocationName = this.formulaItems.at(idx).get("BlockName").value;

        let operationSupportCode = this.costItems.at(idx).get("CostTypeCode").value;
        let operationSupportName = this.costItems.at(idx).get("CostTypeName").value;

        let costItemlatest = this.costItems.at(this.costItems.length - 1);

        if (symbol != "(" && symbol != ")" && !costItemlatest.get("CostFlag").value && (costItemlatest.get("Symbol").value == "+" || costItemlatest.get("Symbol").value == "-" || costItemlatest.get("Symbol").value == "*" || costItemlatest.get("Symbol").value == "/")) {
          return
        }

        let countParenthesesOpen = (this.costItems as FormArray).controls.filter(
          x => x.get("Symbol").value == "("
        ).length;

        let countParenthesesClose = (this.costItems as FormArray).controls.filter(
          x => x.get("Symbol").value == ")"
        ).length;

        let checkAddCost = false;

        if (symbol == ")") {
          if (countParenthesesOpen > countParenthesesClose) {
            checkAddCost = true;
          }
        } else if (symbol == "(") {
          checkAddCost = true;
        } else {
          if (costItemlatest.get('CostFlag').value) {
            checkAddCost = true;
          }
        }

        //if (checkAddCost) {
        this.costItems.push(this.formBuilder.group({
          "Symbol": new FormControl({ value: symbol, disabled: true }),
          "CostFlag": new FormControl({ value: false, disabled: true }),
          "Priority": new FormControl({ value: (this.costItems.length + 1), disabled: true }),
          "BlockCode": new FormControl({ value: (formulaAllocationCode == null || formulaAllocationCode == "") ? "ALL" : formulaAllocationCode, disabled: true }),
          "BlockName": new FormControl({ value: (formulaAllocationName == null || formulaAllocationName == "") ? "ALL" : formulaAllocationName, disabled: true }),
          "CostTypeCode": new FormControl({ value: operationSupportCode, disabled: true }),
          "CostTypeName": new FormControl({ value: operationSupportName, disabled: true }),
          "CostName": new FormControl({ value: "", disabled: true }),
          "CostId": new FormControl({ value: 0, disabled: true }),
        }));

        let costItems = (this.costItems as FormArray).controls.filter(
          x => x.get("BlockCode").value == formulaAllocationCode
        );

        let formula = "";
        costItems.forEach(detail => {

          let data = "";
          if (detail.get("CostFlag").value) {
            data = "(" + detail.get("CostName").value.trim() + ")";
          } else {
            data = detail.get("Symbol").value;
          }

          formula += " " + data;
        });


        if (formulaAllocationCode == "ALL") {

          (this.formulaItems as FormArray).controls.find(
            x => x.get("BlockCode").value == formulaAllocationCode
          ).patchValue({
            Formula: formula,
          });

        } else {
          let itemAll = this.formulaItems.controls.find(
            x => x.get("BlockCode").value == "ALL"
          );

          (this.formulaItems as FormArray).controls.find(
            x => x.get("BlockCode").value == formulaAllocationCode
          ).patchValue({
            Formula: itemAll.get("Formula").value + formula,
          });
        }
      } else {
        this.costItems.push(this.formBuilder.group({
          "Symbol": new FormControl({ value: symbol, disabled: true }),
          "CostFlag": new FormControl({ value: false, disabled: true }),
          "Priority": new FormControl({ value: (this.costItems.length + 1), disabled: true }),
          "BlockCode": new FormControl({ value: "ALL", disabled: true }),
          "BlockName": new FormControl({ value: "ALL", disabled: true }),
          "CostTypeCode": new FormControl({ value: "", disabled: true }),
          "CostTypeName": new FormControl({ value: "", disabled: true }),
          "CostName": new FormControl({ value: "", disabled: true }),
          "CostId": new FormControl({ value: 0, disabled: true }),
        }));

        let costItems = (this.costItems as FormArray).controls.filter(
          x => x.get("BlockCode").value == "ALL"
        );

        let formula = "";
        costItems.forEach(detail => {

          let data = "";
          if (detail.get("CostFlag").value) {
            data = "(" + detail.get("CostName").value.trim() + ")";
          } else {
            data = detail.get("Symbol").value;
          }

          formula += " " + data;
        });

        (this.formulaItems as FormArray).controls.find(
          x => x.get("BlockCode").value == "ALL"
        ).patchValue({
          Formula: formula,
        });

        this.firstCostFlag = false;
      }
      //}
    } else {

      let formulaAllocationCode = this.formulaItems.at(idx).get("BlockCode").value;

      let formulaAllocationName = this.formulaItems.at(idx).get("BlockName").value;

      let costitemFilter = (this.costItems as FormArray).controls.filter(
        x => x.get("BlockCode").value == formulaAllocationCode
      );

      let costItemLast = costitemFilter[costitemFilter.length - 1]

      let findIndex = (this.costItems as FormArray).controls.findIndex(
        x => x.get("Priority").value == costItemLast.get("Priority").value
      ); 

      this.costItems.removeAt(findIndex);

        let costItems = (this.costItems as FormArray).controls.filter(
          x => x.get("BlockCode").value == formulaAllocationCode
      );

      if (costItems.length != 0) {
        let formula = "";
        costItems.forEach(detail => {
          let data = "";
          if (detail.get("CostFlag").value) {
            data = "(" + detail.get("CostName").value.trim() + ")";
          } else {
            data = detail.get("Symbol").value;
          }
          formula += " " + data;
        });

        if (formulaAllocationCode == "ALL") {

          (this.formulaItems as FormArray).controls.find(
            x => x.get("BlockCode").value == formulaAllocationCode
          ).patchValue({
            Formula: formula,
          });

        } else {
          let itemAll = this.formulaItems.controls.find(
            x => x.get("BlockCode").value == "ALL"
          );

          (this.formulaItems as FormArray).controls.find(
            x => x.get("BlockCode").value == formulaAllocationCode
          ).patchValue({
            Formula: itemAll.get("Formula").value + formula,
          });
        }
      } else {
        this.formZone.get('OperationSupportCode').enable();
        this.formZone.get('OperationSupportCode').updateValueAndValidity();
        this.formZone.get('SummaryMethodCode').enable();
        this.formZone.get('SummaryMethodCode').updateValueAndValidity();

        let index = this.formulaItems.controls.findIndex(
          x => {
            let formulaItemAllocation = x.get("BlockCode").value
            return formulaItemAllocation == formulaAllocationCode
          }
        );

        if (index != -1) {
          this.formulaItems.removeAt(index);
          if (formulaAllocationCode == "ALL") {
            this.firstCostFlag = true;
            this.formulaItems.push(this.formBuilder.group({
              "BlockCode": new FormControl({ value: "ALL", disabled: true }),
              "BlockName": new FormControl({ value: "ALL", disabled: true }),
              "Formula": new FormControl({ value: "", disabled: true }),
            }));
          }
        }
      }
    }
  }

  openCost() {
    this.MasterData.getCostElementConfigurationDetailDataObject("0").subscribe(
      result => {
        const self = this;
        const alertDeleteDialog = this._matDialog.open(CostElementConfigurationModalDeatilComponent, {
          //panelClass: 'select-reviewer-modal',
          disableClose: true,
          maxWidth: undefined,
          height: '300px',
          width: '1000px',
          data: {
            //proposal: proposal || {},
            operationSupportCode: this.formZone.get("OperationSupportCode").value,
            summaryMethodCode: this.formZone.get("SummaryMethodCode").value,
            formulaItemList: this.formulaItems,
            costItemList : this.costItems,
            isReadonly: this.isReadonly,
            resolve: result,
            pageType: 'add',
            action: 'add'
          }
        });

        alertDeleteDialog.afterClosed()
          .subscribe(response => {
            if (response != 'Close' && response.OperationSupportCode != null && response.CostId) {

              this.formZone.get('OperationSupportCode').disable();
              this.formZone.get('OperationSupportCode').updateValueAndValidity();
              this.formZone.get('SummaryMethodCode').disable();
              this.formZone.get('SummaryMethodCode').updateValueAndValidity();

              this.renderCostElemenet(response);
              
            }
          });
      },
      error => console.error(error));
  }

  renderCostElemenet(response) {
    let costSeq = (this.costItems as FormArray).controls.filter(
      x => x.get("CostFlag").value
    ).length;

    let allocationCode = (response.AllocationCode == null || response.AllocationCode == "") ? "ALL" : response.AllocationCode;
    let allocationName = (response.AllocationName == null || response.AllocationName == "") ? "ALL" : response.AllocationName;

    let costPriority = this.costItems.length;

    if (response.Symbol) {
      this.costItems.push(this.formBuilder.group({
        "CostFlag": new FormControl({ value: false, disabled: true }),
        "Priority": new FormControl({ value: (costPriority + 1), disabled: true }),
        "BlockCode": new FormControl({ value: allocationCode, disabled: true }),
        "BlockName": new FormControl({ value: allocationName, disabled: true }),
        "CostTypeCode": new FormControl({ value: "", disabled: true }),
        "CostTypeName": new FormControl({ value: "", disabled: true }),
        "CostName": new FormControl({ value: "", disabled: true }),
        "CostId": new FormControl({ value: 0, disabled: true }),
        "Symbol": new FormControl({ value: response.Symbol, disabled: true }),
      }));

      costPriority = this.costItems.length;
    }

    this.costItems.push(this.formBuilder.group({
      "CostTypeCode": new FormControl({ value: response.OperationSupportCode, disabled: true }),
      "CostTypeName": new FormControl({ value: response.OperationSupportName, disabled: true }),
      "CostId": new FormControl({ value: response.CostId, disabled: true }),
      "Symbol": new FormControl({ value: null, disabled: true }),
      "Priority": new FormControl({ value: (costPriority + 1), disabled: true }),
      "Seq": new FormControl({ value: (costSeq + 1), disabled: true }),
      "CostName": new FormControl({ value: response.CostName, disabled: true }),
      "CostFlag": new FormControl({ value: true, disabled: true }),
      "BlockCode": new FormControl({ value: allocationCode, disabled: true }),
      "BlockName": new FormControl({ value: allocationName, disabled: true }),
    }));

    let index = this.formulaItems.controls.findIndex(
      x => {
        let formulaItemAllocation = x.get("BlockCode").value
        return formulaItemAllocation == allocationCode
      }
    );

    if (index == -1) {
      this.formulaItems.push(this.formBuilder.group({
        "BlockCode": new FormControl({ value: allocationCode, disabled: true }),
        "BlockName": new FormControl({ value: allocationName, disabled: true }),
        "Formula": new FormControl({ value: "", disabled: true }),
      }));
    }

    this.formulaItems.controls.forEach(element => {
      let item = (this.costItems as FormArray).controls.filter(
        x => x.get("BlockCode").value == element.get("BlockCode").value
      );

      let formula = "";
      item.forEach(detail => {

        let data = "";
        if (detail.get("CostFlag").value) {
          data = "(" + detail.get("CostName").value.trim() + ")";
        } else {
          data = detail.get("Symbol").value;
        }
        formula += " " + data;
      });

      if (element.get("BlockCode").value == "ALL") {

        (this.formulaItems as FormArray).controls.find(
          x => x.get("BlockCode").value == element.get("BlockCode").value
        ).patchValue({
          Formula: formula,
        });

      } else {
        let itemAll = this.formulaItems.controls.find(
          x => x.get("BlockCode").value == "ALL"
        );

        (this.formulaItems as FormArray).controls.find(
          x => x.get("BlockCode").value == element.get("BlockCode").value
        ).patchValue({
          Formula: itemAll.get("Formula").value + formula,
        });
      }
    });
  }

  loadCostElemenet() {
    if (this.masterObj?.resolverData && this.masterObj?.resolverData.CostElementItemList){
      this.masterObj?.resolverData.CostElementItemList.forEach(x => {
        let costSeq = (this.costItems as FormArray).controls.filter(
          x => x.get("CostFlag").value
        ).length
        this.costItems.push(this.formBuilder.group({
          "CostTypeCode": new FormControl({ value: x.CostTypeCode, disabled: true }),
          "CostTypeName": new FormControl({ value: x.CostTypeName, disabled: true }),
          "CostId": new FormControl({ value: x.CostId, disabled: true }),
          "Symbol": new FormControl({ value: x.Symbol, disabled: true }),
          "Priority": new FormControl({ value: x.Priority, disabled: true }),
          "Seq": new FormControl({ value: (costSeq + 1), disabled: true }),
          "CostName": new FormControl({ value: x.CostName, disabled: true }),
          "CostFlag": new FormControl({ value: x.CostFlag, disabled: true }),
          "BlockCode": new FormControl({ value: x.BlockCode, disabled: true }),
          "BlockName": new FormControl({ value: x.BlockName, disabled: true }),
        }));
      });
    }

    if (this.masterObj?.resolverData && this.masterObj?.resolverData.CostElementFormulaDtoList){
      this.masterObj?.resolverData.CostElementFormulaDtoList.forEach(x => {
        let blockName = "";
        if (x.BlockCode == "ALL") {
          blockName = "ALL";
        } else {
          blockName = this.blockList.find(element => element.Code == x.BlockCode)?.BlockName;
        }
        this.formulaItems.push(this.formBuilder.group({
          "BlockCode": new FormControl({ value: x.BlockCode, disabled: true }),
          "BlockName": new FormControl({ value: blockName, disabled: true }),
          "Formula": new FormControl({ value: x.Formula, disabled: true }),
        }));
      });
    }
    
  }

  deleteCost(index) {
    this.costItems.removeAt(index);
  }

  save() {
    this.formZone.markAllAsTouched();
    var requestObj = this.formZone.getRawValue();
    var message = "";

    if (this.costItems.length <= 0) {
      this.alertDialog.open(AlertModalComponent, {
        disableClose: true,
        data: {
          message: 'Please add Cost Element.',
          buttonText: {
            cancel: 'OK'
          }
        }
      });
      return;
    }

    if (!this.formZone.valid) {
    
      return;
    }

    if (this.paramId) {
      requestObj["CodeEncoded"] = this.paramId == 0 ? null : this.paramId;
    }

    this.MasterData.checkCostElement(requestObj).subscribe(
      result => {
        if (result.status == "invalid" || result.status == "ERROR") {
          //this.matDialogRef.close(true);
          this.alertDialog.open(AlertModalComponent, {
            disableClose: true,
            data: {
              message: result.detail,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          const dialogRef = this.confirmDialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: 'Would you like to save?',
              buttonText: {
                ok: 'Save',
                cancel: 'No'
              }
            }
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.MasterData.saveCostElementConfiguration(requestObj).subscribe(
                result => {
                  const alertDialog = this.alertDialog.open(AlertModalComponent, {
                    disableClose: true,
                    data: {
                      message: 'Save Successfully.',
                      buttonText: {
                        cancel: 'OK'
                      }
                    }
                  });
                  alertDialog.afterClosed()
                    .subscribe(response => {
                      this.router.navigate(['/cost-element-configuration']);
                    });
                },
                error => console.error(error)
              )
            }
          });
        }
      },
      error => console.error(error)
    );
  }

  startYearChangeValue() {
    var startYear = this.formZone.get('StartYear').value;
    this.endYearList = this.yearList.filter(x => x.Code >= startYear);
   
  }

  endYearChangeValue() {
    var endYear = this.formZone.get('EndYear').value;
    this.startYearList = this.yearList.filter(x => x.Code <= endYear);
  }

  changeOperationSupport() {

    this.formZone.get("SummaryMethodCode").setValue(null);

    this.summaryMethodList = this.masterObj.tcSummaryMethod;

    if (this.formZone.get("OperationSupportCode").value == 'RI') {
      this.summaryMethodList = this.masterObj.tcSummaryMethod.filter(
        x => x.Code == "MB"
      );
    } else if (this.formZone.get("OperationSupportCode").value == 'VE') {
      this.summaryMethodList = this.masterObj.tcSummaryMethod.filter(
        x => x.Code == "MB" || x.Code == "VB"
      );
    } else {
      this.summaryMethodList = this.masterObj.tcSummaryMethod.filter(
        x => x.Code == "B"
      );
    }

  }
}
